import { doc, DocumentData, getDoc, setDoc } from 'firebase/firestore';
import { db } from '..';
import { IUserDoc } from '../../interfaces/user.interface';

export const initUserDoc = async (uid: string): Promise<void> => {
  const userDoc = {
    uid,
    authProvider: 'local',

    subLvl: 0,

    balance: null,
    purchaseType: null,
    lastPurchaseDate: null,
    lastPurchaseAmount: null,
  } as IUserDoc;

  await setDoc(doc(db, 'users', uid), userDoc);
};

export const getUserInfo = async (
  uid: string
): Promise<DocumentData | boolean> => {
  const initialDoc = {
    authProvider: null,
    lastPurchaseDate: null,
    purchaseType: null,
    subLvl: 0,
  };

  const docSnap = await getDoc(doc(db, 'users', uid));

  if (docSnap.exists()) return docSnap.data();

  return initialDoc;
};

import { put, takeEvery } from 'redux-saga/effects';

import { sendMessageFunc } from '../../utils/sendMessageFunc';
import { API_ERROR } from '../auth/actionTypes';
import { sendMessageSuccess } from './actions';
import { SEND_MESSAGE } from './actionTypes';

function* sendMessageSaga({ payload }) {
  try {
    yield sendMessageFunc(
      payload.email,
      payload.name,
      payload.subject,
      payload.message
    );
    yield put(sendMessageSuccess());
  } catch (error) {
    yield put(API_ERROR());
  }
}

function* contactSaga() {
  yield takeEvery(SEND_MESSAGE, sendMessageSaga);
}

export default contactSaga;

import * as Yup from 'yup';

const getValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
  });
};

export default getValidationSchema;

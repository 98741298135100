import './styles.scss';
const Mouse = () => (
  <section className="mouse content-center">
    <div className="mouse-container">
      <div className="mouse-icon">
        <div className="scroller" />
      </div>
    </div>
  </section>
);

export default Mouse;

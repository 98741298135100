import emailjs from 'emailjs-com';
import { toastSuccess, toastWarning } from './toasts';

export const sendMessageFunc = async (
  email: string,
  name: string,
  subject: string,
  message: string
) => {
  if (message) {
    await new Promise(resolve => setTimeout(resolve, 800));
    emailjs.send('service_7dpixfm', 'template_03zfpjr', {
      subject,
      from_name: name,
      message,
      reply_to: email,
    });
    toastSuccess('Message successfully sent!');
  } else if (!message) {
    toastWarning('Text message is required');
  } else {
    toastWarning('Oops, something went wrong!');
  }
};

import { downloadCards } from './cards';

import AccountCard from './components/AccountCard';
import BlurContainer from 'components/BlurContainer';
import RoleManager from '../../../components/Layout/RoleManager';
import AccountInfoContainer from './components/AccountInfoContainer';

import './styles.scss';

const DownloadCardsRender = () => (
  <div className="account-cards content-between is-column">
    {downloadCards.map(({ title, imgH, imgV }) => (
      <BlurContainer>
        <AccountCard key={title} title={title} imgH={imgH} imgV={imgV} />
      </BlurContainer>
    ))}
  </div>
);

const AccountPage = () => (
  <RoleManager>
    <section className="account is-row align-center">
      <DownloadCardsRender />
      <AccountInfoContainer />
    </section>
  </RoleManager>
);

export default AccountPage;

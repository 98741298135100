import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { login } from '../../../store/actions';

import LogInForm from './LogInForm';
import getValidationSchema from './logInPageFormValidationSchema';

const LogInFormContainer = ({
  isEditProfileRedirect,
  initialValues = {
    email: '',
    password: '',
  },
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={(form) =>
        dispatch(login(form, navigate, isEditProfileRedirect))
      }
    >
      {({ values }) => <LogInForm values={values} />}
    </Formik>
  );
};

export default LogInFormContainer;

import { db } from '..';
import { doc, setDoc, getDoc, getDocs, collection } from 'firebase/firestore';
import { IProxyDoc } from '../../interfaces/proxy.interface';

export const addProxyDoc = async (data: IProxyDoc): Promise<void> =>
  await setDoc(doc(db, 'proxy', data.address), data);

export const editProxyDoc = async (data: IProxyDoc): Promise<void> =>
  await setDoc(doc(db, 'proxy', data.address), data);

export const getProxyDoc = async ({
  address,
}: {
  address: string | undefined;
}) => {
  if (!address) {
    return await getDocs(collection(db, 'proxy'))
      .then((data) =>
        data.docs.map((doc) => {
          const data = doc.data() as IProxyDoc;

          return { value: data.address, label: data.address, ...data };
        })
      )
      .catch((err) => {
        throw err;
      });
  }

  const docSnap = await getDoc(doc(db, 'proxy', address));

  if (docSnap.exists()) {
    return docSnap.data();
  }
};

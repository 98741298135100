import {
  ADD_ARTICLE,
  ADD_ARTICLE_SUCCESS,
  GET_ARTICLE,
  GET_ARTICLE_SUCCESS,
  EDIT_ARTICLE,
  EDIT_ARTICLE_SUCCESS,
} from './actionTypes';

export const editArticle = (payload) => {
  return {
    type: EDIT_ARTICLE,
    payload,
  };
};
export const editArticleSuccess = () => {
  return {
    type: EDIT_ARTICLE_SUCCESS,
  };
};

export const addArticle = (payload) => {
  return {
    type: ADD_ARTICLE,
    payload,
  };
};
export const addArticleSuccess = () => {
  return {
    type: ADD_ARTICLE_SUCCESS,
  };
};

export const getArticle = (payload) => {
  return {
    type: GET_ARTICLE,
    payload,
  };
};

export const getArticleSuccess = (payload) => {
  return {
    type: GET_ARTICLE_SUCCESS,
    payload,
  };
};

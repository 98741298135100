import { useState } from 'react';
import { useSelector } from 'react-redux';

import FaqArticle from '../FaqArticle';
import Loader from '../../../../../components/Loader';

const FaqArticlesContainer = ({ filteredArticles }) => {
  const [opened, setOpened] = useState([]);

  const { loading } = useSelector((state) => state.Article);

  if (loading) return <Loader customClassName="faq-loading-icon" />;

  if (!filteredArticles)
    return <div className="faq-not-found karla">Nothing were found</div>;

  return filteredArticles.map(({ textarea, title }, idx) => (
    <FaqArticle
      key={title}
      index={idx}
      title={title}
      text={textarea}
      opened={opened}
      setOpened={setOpened}
    />
  ));
};

export default FaqArticlesContainer;

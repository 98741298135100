import { SEND_MESSAGE, SEND_MESSAGE_SUCCESS } from './actionTypes';
import { API_ERROR } from '../auth/actionTypes';

const initialState = {
  email: null,
  name: null,
  subject: null,
  message: null,
  loading: false,
};

const contact = (state = initialState, action) => {
  switch (action.type) {
    case SEND_MESSAGE:
      return {
        ...state,
        loading: true,
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default contact;

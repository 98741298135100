const FaqArticleToggle = ({ props: { onClick, icon }, index }) => (
  <label
    onClick={() => onClick(index)}
    htmlFor={`toggle-checkbox-${index}`}
    className="select-none green-label"
  >
    {icon}
    <input type="checkbox" id={`toggle-${index}`} className="checkbox-hidden" />
  </label>
);

const FaqArticle = ({ text, title, opened, index, setOpened }) => {
  function checkForArticle(opened, index) {
    if (opened.includes(index)) {
      return {
        onClick: () => setOpened(opened.filter((article) => article !== index)),
        icon: '-',
        isOpened: true,
      };
    }

    return {
      onClick: () => setOpened([...opened, index]),
      icon: '+',
      isOpened: false,
    };
  }
  return (
    <label
      className="faq-article"
      htmlFor={`toggle-${index}`}
      id={`toggle-checkbox-${index}`}
    >
      <h4>
        {title}
        <FaqArticleToggle
          props={checkForArticle(opened, index)}
          index={index}
        />
      </h4>
      <span
        className={`faq-article-text-${
          checkForArticle(opened, index).isOpened && 'opened'
        }`}
      >
        {text}
      </span>
    </label>
  );
};

export default FaqArticle;

import { Link } from 'react-router-dom';

import './styles.scss';

const AccountLinks = () => (
  <div className="account-links">
    <Link to="/contact" className="account-link karla">
      Hit us up
    </Link>
    <Link to="/faq" className="account-link karla">
      FAQ
    </Link>
  </div>
);

export default AccountLinks;

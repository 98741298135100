import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import './styles.scss';
const FieldError = ({ children, className }) => {
  if (children && typeof children !== 'string') {
    return (
      <div className={`error-message my-0 mx-2 ${className}`}>
        {Object.keys(children).map((fieldKey) => (
          <div key={`error-${v4()}`}>
            {fieldKey}: {JSON.stringify(children[fieldKey])}
          </div>
        ))}
      </div>
    );
  } else {
    return <span className={`error-message ${className}`}>*{children}</span>;
  }
};

FieldError.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
};

export default FieldError;

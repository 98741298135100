import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { prefix, links } from './constants';

import Redactor from '../Redactor';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { addArticle, editArticle, getArticle } from '../../../../store/actions';
import ProxyManager from '../ProxyManager';
import { v4 } from 'uuid';

const Navigation = () => (
  <section className="navigation-container">
    <NavLink />
    <NavRoutes />
  </section>
);

const NavLink = () => {
  const { pathname } = useLocation();

  return (
    <div className="links-container rounded">
      {links.map(({ to, name }) => (
        <Link
          to={prefix + to}
          className={`karla ${pathname.includes(to) && 'active'}`}
          key={`nav-link-${v4()}`}
        >
          {name}
        </Link>
      ))}
    </div>
  );
};

const NavRoutes = () => {
  const dispatch = useDispatch();

  const handleAddSubmit = (values) => {
    dispatch(addArticle(values));
  };
  const handleEditSubmit = (values) => {
    dispatch(editArticle(values));
  };

  const handleGetArticle = (values) => {
    dispatch(getArticle(values));
  };

  return (
    <Routes>
      {['news', 'faq', 'tutorials', 'proxy'].map((type) => (
        <Route
          key={`route-${type}`}
          path={type}
          element={
            type !== 'proxy' ? (
              <Redactor
                type={type}
                handleAddSubmit={handleAddSubmit}
                handleEditSubmit={handleEditSubmit}
                handleGetArticle={handleGetArticle}
              />
            ) : (
              <ProxyManager />
            )
          }
        />
      ))}
    </Routes>
  );
};
export default Navigation;

import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  theme: 'dark',
} as ToastOptions;

export const toastSuccess = (message: string) =>
  toast.success(message, defaultOptions);

export const toastWarning = (message: string) =>
  toast.warning(message, defaultOptions);

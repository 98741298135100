import './styles.scss';

const BurgerButton = ({ childRef, isOpen, onClick }) => (
  <button
    ref={childRef}
    onClick={onClick}
    className={`menu-btn ${isOpen && 'active'}`}
  >
    <span className={`top ${isOpen && 'active'}`} />
    <span className={`middle ${isOpen && 'active'}`} />
    <span className={`bottom ${isOpen && 'active'}`} />
  </button>
);

export default BurgerButton;

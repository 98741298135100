import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';

import FormikError from '../FormikError/index';

const CheckoutFormField = ({
  name,
  label,
  id,
  type,
  customClassName,
  ...restProps
}) => (
  <label className={classNames('checkout-item align-center')} htmlFor={id}>
    <Field
      name={name}
      id={id}
      {...(type && { type })}
      {...restProps}
      className={classNames('checkout-box', customClassName)}
    />
    <label className="checkout-description content-start karla" htmlFor={id}>
      {label}
    </label>
    <ErrorMessage component={FormikError} name={name} />
  </label>
);

export default CheckoutFormField;

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const AUTH_UPDATE = 'AUTH_UPDATE';
export const AUTH_UPDATE_SUCCESS = 'AUTH_UPDATE_SUCCESS';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
export const UPDATE_CREDENTIALS_SUCCESS = 'UPDATE_CREDENTIALS_SUCCESS';

export const API_ERROR = 'LOGIN_API_ERROR';

import {
  LOGIN,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  API_ERROR,
  AUTH_UPDATE_SUCCESS,
  UPDATE_CREDENTIALS,
  UPDATE_CREDENTIALS_SUCCESS,
} from './actionTypes';

const initialState = {
  user: null,
  error: null,
  isAuth: false,
  loading: false,
  isAdmin: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isAuth: action.payload.isAuth,
        isAdmin: action.payload.isAdmin,
        loading: false,
      };
    case REGISTER:
      return {
        ...state,
        loading: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isAuth: action.payload.isAuth,
        loading: false,
      };

    case UPDATE_CREDENTIALS:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case AUTH_UPDATE_SUCCESS:
      return {
        ...state,
        isAuth: action.payload,
      };
    case LOGOUT:
      return initialState;
    case LOGOUT_SUCCESS:
      return initialState;
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default auth;

import { put, takeEvery } from 'redux-saga/effects';

import { addProxyDoc, editProxyDoc, getProxyDoc } from '../../firebases/docs';
import { toastSuccess, toastWarning } from '../../utils/toasts';

import { addProxySuccess, editProxySuccess, getProxySuccess } from './actions';
import { apiError } from '../actions';
import { ADD_PROXY, EDIT_PROXY, GET_PROXY } from './actionTypes';

function* addProxySaga({ payload }) {
  try {
    yield addProxyDoc(payload);
    yield put(addProxySuccess());

    toastSuccess('New Proxy added successfully');
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError(error.message));
  }
}

function* editProxySaga({ payload }) {
  try {
    yield editProxyDoc(payload);
    yield put(editProxySuccess());

    toastSuccess('Proxy edited successfully');
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError(error.message));
  }
}

function* getProxySaga({ payload }) {
  try {
    const proxy = yield getProxyDoc(payload);

    yield put(getProxySuccess(proxy));
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError(error.message));
  }
}

function* ProxySaga() {
  yield takeEvery(ADD_PROXY, addProxySaga);
  yield takeEvery(EDIT_PROXY, editProxySaga);
  yield takeEvery(GET_PROXY, getProxySaga);
}

export default ProxySaga;

import { useState } from 'react';
import { v4 } from 'uuid';
import Button from '../../../../components/Buttons/Button';
import ProxyList from './components/ProxyList';
import ProxyRedactor from './components/ProxyRedactor';
import './styles.scss';

const ProxyManager = () => {
  const types = ['Add', 'Edit', 'List'];
  const [selectedType, setSelectedType] = useState(types[2]);

  return (
    <div className="proxy-manager-container">
      <div className="heading karla">
        {types.map((type) => (
          <Button
            text={type}
            key={`redactor-btn-${v4()}`}
            onClick={() => setSelectedType(type)}
            color={`trans ${type.includes(selectedType) ? 'active' : ''}`}
          />
        ))}
      </div>

      <div className="content-container">
        {selectedType.includes(types[2]) ? (
          <ProxyList />
        ) : (
          <ProxyRedactor type={selectedType} />
        )}
      </div>
    </div>
  );
};

export default ProxyManager;

import { v4 } from 'uuid';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Layout from './components/Layout';
import {
  userRoutes,
  adminRoutes,
  notFoundPage,
  publicRoutes,
} from './routes/routeList';

import './App.scss';

function App() {
  const { isAuth, isAdmin } = useSelector((state) => state.Auth);
  const memoRoutes = useMemo(
    () => [...(isAuth ? userRoutes : []), ...(isAdmin ? adminRoutes : [])],
    [isAuth, isAdmin]
  );

  return (
    <Layout>
      <Routes>
        {[...publicRoutes, ...notFoundPage, ...memoRoutes].map(
          ({ path, component: Component, exact, ...rest }) => (
            <Route
              key={v4()}
              path={path}
              exact={exact}
              element={<Component {...rest} />}
            />
          )
        )}
      </Routes>
      <ToastContainer />
    </Layout>
  );
}

export default App;

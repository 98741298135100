import './styles.scss';

const AccountDataField = ({ title, value }) => (
  <div className="account-data-container">
    <div className="account-data-field">
      <span className="account-data-title karla">{title}</span>
      <span className="account-data-value karla">{value}</span>
    </div>
    <div className="account-data-line" />
  </div>
);

export default AccountDataField;

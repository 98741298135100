import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

import RegisterForm from './RegisterForm';

import { register } from '../../../store/actions';
import getValidationSchema from './registerFormValidationSchema';
import { useNavigate } from 'react-router-dom';

const RegisterFormContainer = ({
  initialValues = {
    email: '',
    name: '',
    password: '',
    passwordConfirmation: '',
  },
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={(form) => dispatch(register({ form, navigate }))}
    >
      {({ values }) => <RegisterForm values={values} />}
    </Formik>
  );
};

export default RegisterFormContainer;

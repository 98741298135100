export enum Level {
  NONE = 0,
  SIMPLE = 1,
  MEDIUM = 2,
  PREMIUM = 3,
}

export const LevelPrice = {
  [Level.NONE]: 0,
  [Level.SIMPLE]: 25,
  [Level.MEDIUM]: 55,
  [Level.PREMIUM]: 95,
};

export const LevelLowLabel = {
  [Level.NONE]: 'none',
  [Level.SIMPLE]: 'simple',
  [Level.MEDIUM]: 'medium',
  [Level.PREMIUM]: 'premium',
};

export const LevelLabel = {
  [Level.NONE]: "No subscription",
  [Level.SIMPLE]: 'Simple',
  [Level.MEDIUM]: 'Medium',
  [Level.PREMIUM]: 'Premium',
};

import ArticleFormContainer from '../../../../../components/Forms/ArticleForm/ArticleFormContainer';

import './styles.scss';

const ArticleRedactor = ({ type, format: { title, onSubmit } }) => {
  return (
    <div className="article-redactor-container rounded">
      <span>{title}</span>

      {['news', 'faq', 'tutorials'].includes(type) && (
        <ArticleFormContainer onSubmit={onSubmit} type={type} title={title} />
      )}
    </div>
  );
};

export default ArticleRedactor;

import { put, takeEvery } from 'redux-saga/effects';

import { apiError } from '../actions';
import { GET_USER_INFO } from './actionTypes';
import { getUserInfoSuccess } from './actions';

import { toastWarning } from '../../utils/toasts';
import { getUserInfo } from '../../firebases/docs';

function* getUserInfoSaga({ payload }) {
  try {
    const data = yield getUserInfo(payload);
    yield put(getUserInfoSuccess(data));
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(GET_USER_INFO, getUserInfoSaga);
}

export default authSaga;

export enum ProxyStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BUSY = 'busy',
  CHILL = 'chill',
}

export const ProxyStatusLabel = {
  [ProxyStatus.ACTIVE]: 'Active',
  [ProxyStatus.INACTIVE]: 'Inactive',
  [ProxyStatus.BUSY]: 'Busy',
  [ProxyStatus.CHILL]: 'Chill',
};

export const ProxyStatusColorIndication = {
  [ProxyStatus.ACTIVE]: 'success',
  [ProxyStatus.INACTIVE]: 'warning',
  [ProxyStatus.BUSY]: 'secondary',
  [ProxyStatus.CHILL]: 'dark',
};

export const ProxyStatusListOptions = Object.entries(ProxyStatusLabel).map(
  ([proxyKey, proxyLabel]) => ({
    value: proxyKey,
    label: proxyLabel,
  })
);

import { Form } from 'formik';
import { useSelector } from 'react-redux';

import Button from '../../Buttons/Button';
import { FormField } from '../../Formik/Fields/index';

import './styles.scss';

const formFields = () =>
  [
    {
      id: 'name',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Your name',
      show: true,
    },
    {
      id: 'email',
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Your email',
      show: true,
    },
    {
      id: 'password',
      name: 'password',
      label: 'Password',
      placeholder: 'Your password',
      show: true,
    },
    {
      id: 'passwordConfirmation',
      name: 'passwordConfirmation',
      label: 'Password Confirmation',
      placeholder: 'Confirm your password',
      show: true,
    },
  ].filter(({ show }) => show);

const renderFormField = ({ show, ...fieldProps }) => (
  <FormField key={fieldProps.name} {...fieldProps} />
);

const RegisterForm = ({ initialValues }) => {
  const { loading } = useSelector((state) => state.Auth);

  return (
    <Form className="register-form is-column align-center" autoComplete="off">
      {formFields({
        initialValues,
      }).map(renderFormField)}

      <div className="full-width content-center">
        <Button text="Sign Up" loading={loading} />
      </div>
    </Form>
  );
};

export default RegisterForm;

import { Form } from 'formik';
import { useEffect, useState } from 'react';

import { DropdownFormField } from '../../Formik/Fields';
import IconArrowDown from '../../../assets/ArrowDown.svg';
import IconArrowUpward from '../../../assets/ArrowUpward.svg';

import './styles.scss';

const formFields = (active) =>
  [
    {
      id: 'all',
      name: 'value',
      value: 'all',
      label: 'All',
      type: 'radio',
      show: true,
    },
    {
      id: 'tips',
      name: 'value',
      value: 'tips',
      label: 'Tips',
      type: 'radio',
      show: true,
    },
    {
      id: 'update',
      name: 'value',
      value: 'update',
      label: 'Update',
      type: 'radio',
      show: true,
    },
  ].filter(({ show }) => show);

const renderDropdownFormField = ({ show, ...fieldProps }) => (
  <DropdownFormField key={fieldProps.value} {...fieldProps} />
);

const AccountForm = ({ values }) => {
  useEffect(() => {
    document.getElementById('form-submit').click();
    setActive(false);
  }, [values]);

  const [active, setActive] = useState(false);

  return (
    <>
      <Form className="dropdown-form is-column align-center" autoComplete="off">
        <div
          className={`dropdown-filter karla ${active && 'dropdown-active'}`}
          onClick={() => setActive(!active)}
        >
          <div className="dropdown-filter-container">
            <span>Section:</span>
            <label className="dropdown-filter-value">{values?.value}</label>
          </div>
          {active ? (
            <img
              src={IconArrowUpward}
              id="dropdown-arrow"
              alt=""
              className="arrow-dropdown"
            />
          ) : (
            <img
              src={IconArrowDown}
              id="dropdown-arrow"
              alt=""
              className="arrow-dropdown"
            />
          )}
        </div>
        {active && (
          <div className="dropdown-fields-container">
            {formFields().map(renderDropdownFormField)}
          </div>
        )}
        <button id="form-submit" type="submit" className="dropdown-submit">
          Filter
        </button>
      </Form>
    </>
  );
};

export default AccountForm;

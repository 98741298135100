import AdminPage from '../pages/AdminPage';
import FaqPage from '../pages/User/FaqPage';
import HomePage from '../pages/User/HomePage';
import LogInPage from '../pages/Auth/LogInPage';
import PageNotFound from '../pages/NotFoundPage';
import SignUpPage from '../pages/Auth/SignUpPage';
import ContactPage from '../pages/User/ContactPage';
import AccountPage from '../pages/User/AccountPage';
import CheckoutPage from '../pages/User/CheckoutPage';
import EditProfilePage from '../pages/Auth/EditProfilePage';
import PasswordResetPage from '../pages/Auth/PasswordResetPage';
import SubscriptionsPage from '../pages/User/SubscriptionsPage';

const publicRoutes = [
  {
    path: `/`,
    exact: true,
    component: HomePage,
  },
  {
    path: `/sign-up`,
    exact: true,
    component: SignUpPage,
  },
  {
    path: `/log-in`,
    exact: true,
    component: LogInPage,
  },
  {
    path: `/faq`,
    exact: true,
    component: FaqPage,
  },
  {
    path: `/contact`,
    exact: true,
    component: ContactPage,
  },

  {
    path: `/password-reset`,
    exact: true,
    component: PasswordResetPage,
  },
  {
    path: `*`,
    exact: true,
    component: PageNotFound,
  },
];

const userRoutes = [
  {
    path: `/edit-profile`,
    exact: true,
    component: EditProfilePage,
  },
  {
    path: `/account`,
    exact: true,
    component: AccountPage,
  },
  {
    path: `/subscriptions`,
    exact: true,
    component: SubscriptionsPage,
  },
  {
    path: `/checkout`,
    exact: true,
    component: CheckoutPage,
  },
];

const adminRoutes = [
  {
    path: `/@dmin-d@shboard/*`,
    exact: false,
    component: AdminPage,
  },
];

const notFoundPage = [{ path: '*', component: PageNotFound }];

export { userRoutes, publicRoutes, notFoundPage, adminRoutes };

import { PROCEED_CHECKOUT, PROCEED_CHECKOUT_SUCCESS } from './actionTypes';

export const proceedCheckout = (payload) => {
  return {
    type: PROCEED_CHECKOUT,
    payload,
  };
};

export const proceedCheckoutSuccess = (payload) => {
  return {
    type: PROCEED_CHECKOUT_SUCCESS,
    payload,
  };
};

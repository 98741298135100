import Links from './Links';
import { buttonToRender } from '../buttonToRender';

const DesktopHeader = ({
  user,
  isAdmin,
  navigate,
  dispatch,
  isMainPage,
  isLogInPage,
  isAuth,
  isAdminPage,
  isAccountPage,
}) => (
  <>
    {isMainPage && <Links />}
    {isAdminPage && <h1 className="page-title cairo">Admin Page</h1>}
    <div className="align-center content-between header-box">
      {isAccountPage && <h3>Hi, {user}</h3>}
      {buttonToRender(
        isAccountPage,
        isLogInPage,
        isAuth,
        navigate,
        dispatch,
        isAdmin
      )}
    </div>
  </>
);

export default DesktopHeader;

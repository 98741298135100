import { useMemo } from 'react';
import { getArticleDoc } from '../../../../../firebases/docs';
import AutocompleteField from '../AutocompleteField';

const optionsMapper = ({ firstName, lastName, id }) => ({
  label: `${(firstName, lastName)}`,
  value: id,
});

const TitleAutocomplete = (props) => {
  const dataFetchFilterOptions = useMemo(
    () => ({ $take: 15, collectionName: props.collection }),
    [props.collection]
  );

  return (
    <AutocompleteField
      {...props}
      fetchDataCall={getArticleDoc}
      dataFetchFilterOptions={dataFetchFilterOptions}
      optionsMapper={props.optionsMapper || optionsMapper}
    />
  );
};

export default TitleAutocomplete;

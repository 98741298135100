import { Formik } from 'formik';

import FaqForm from './FaqForm';

const AccountFormContainer = ({
  initialValues = {
    value: 'all',
  },
  onSubmit,
}) => {
  return (
    <div className="dropdown-form-container">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={({ value }) => onSubmit(value)}
        className="dropdown-fields"
      >
        {({ values }) => <FaqForm values={values} />}
      </Formik>
    </div>
  );
};

export default AccountFormContainer;

import PriceCard from './PriceCard';
import { cardList } from './cardList';

import './styles.scss';
import { v4 } from 'uuid';

const Prices = () => (
  <section className="price is-four-fifth" id="price">
    <div className="price-heading cairo">How much does it cost?</div>
    <div className="price-cards">
      {cardList.map(
        ({ icon, list, color, title, subtitle, iconWidth, customClass }) => (
          <PriceCard
            key={`price-card-${v4()}`}
            icon={icon}
            list={list}
            color={color}
            title={title}
            subtitle={subtitle}
            iconWidth={iconWidth}
            customClass={customClass}
          />
        )
      )}
    </div>
  </section>
);

export default Prices;

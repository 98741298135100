import { Form } from 'formik';
import Button from '../../Buttons/Button';

import { CheckoutFormField } from '../../Formik/Fields/index';

import './styles.scss';

const formFields = () =>
  [
    {
      id: 'crypto',
      value: 'crypto',
      label: 'Credit card / Crypto',
      name: 'picked',
      type: 'radio',
      show: true,
    },
    {
      id: 'paypal',
      label: 'Paypal',
      value: 'paypal',
      name: 'picked',
      type: 'radio',
      show: true,
    },
  ].filter(({ show }) => show);

const renderFormField = ({ show, ...fieldProps }) => (
  <CheckoutFormField key={fieldProps.value} {...fieldProps} />
);

const CheckoutForm = ({ initialValues, loading }) => (
  <Form className="checkout-form is-column" autoComplete="off">
    {formFields({
      initialValues,
    }).map(renderFormField)}

    <div className="checkout-button-container content-center">
      <Button text="Checkout" color="primary" loading={loading} />
    </div>
  </Form>
);

export default CheckoutForm;

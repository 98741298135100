import { Form } from 'formik';
import { ProxyStatusListOptions } from '../../../enums/proxy.enum';

import Button from '../../Buttons/Button';
import { FormField } from '../../Formik/Fields';
import ProxyAutocomplete from '../../Formik/Fields/autocomplete/ProxyAutocomplete';
import SelectFormField from '../../Formik/Fields/SelectFormField';

import './styles.scss';

const fields = {
  searchFields: ({ setFieldValue }) => [
    {
      id: 'addressSearch',
      type: 'text',
      name: 'addressSearch',
      label: 'Search by address',
      placeholder: '87.251.18.93',

      setFieldValue,
      collection: 'proxy',
      component: ProxyAutocomplete,
      otherValueAutoSet: ['port', 'address', 'login', 'pwd', 'status'],
    },
  ],
  settingsFields: () => [
    {
      id: 'address',
      type: 'text',
      name: 'address',
      label: 'Address',
      placeholder: '87.251.18.93',
    },
    {
      id: 'port',
      type: 'text',
      name: 'port',
      label: 'Port',
      placeholder: '45785',
    },
  ],
  credentialsFields: () => [
    {
      id: 'login',
      type: 'text',
      name: 'login',
      label: 'Login',
      placeholder: 'Login',
    },
    {
      id: 'pwd',
      type: 'text',
      name: 'pwd',
      label: 'PWD',
      placeholder: 'PWD',
    },
  ],
  proxyField: ({ values }) => [
    {
      type: 'select',
      id: 'status',
      name: 'status',
      label: 'status:',
      isMulti: false,
      isClearable: false,
      component: SelectFormField,
      options: ProxyStatusListOptions,
      selectedOption: ProxyStatusListOptions.find(
        (o) => o.value === values.status
      ),
    },
  ],
};

const renderFormField = ({ show, ...fieldProps }) => (
  <FormField key={fieldProps.name} {...fieldProps} />
);

const ProxyForm = ({ type, loading, setFieldValue, values }) => {
  return (
    <Form className="proxy-form is-column align-center" autoComplete="off">
      {type === 'Edit' && (
        <div className="search-proxy">
          {fields.searchFields({ setFieldValue }).map(renderFormField)}
        </div>
      )}

      <div className="proxy-fields-container">
        <div className="proxy-settings">
          {fields.settingsFields().map(renderFormField)}
        </div>

        <div className="proxy-credentials">
          {fields.credentialsFields().map(renderFormField)}
        </div>
      </div>

      {fields.proxyField({ values }).map(renderFormField)}

      <div className="full-width content-center">
        <Button text={type} loading={loading} />
      </div>
    </Form>
  );
};

export default ProxyForm;

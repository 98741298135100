export function firebaseErrFormatter(message: string): string {
  if (message.includes('user-not-found')) {
    return 'Email is not defined';
  }
  if (message.includes('wrong-password')) {
    return 'Password is not correct';
  }
  if (message.includes('email-already-in-use')) {
    return 'Such email is already registered';
  }
  if (message.includes('log-in-first')) {
    return 'You have to log in first'
  }

  return 'Unrecognized error, try later';
}

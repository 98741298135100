import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../../../firebases';

import AccountDataField from '../AccountDataField';
import Button from '../../../../../components/Buttons/Button';

import './styles.scss';

const AccountData = () => {
  const [user] = useAuthState(auth);

  return (
    <div className="account-data">
      <div className="account-data-heading-container">
        <span className="account-data-heading cairo">Profile</span>
      </div>
      <AccountDataField title={'Name'} value={user?.displayName} />
      <AccountDataField title={'Email'} value={user?.email} />
      <div className="account-button">
        <Button text="Edit profile" path="/edit-profile" />
      </div>
    </div>
  );
};

export default AccountData;

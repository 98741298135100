import classNames from 'classnames';

import { ReactComponent as LoadingStar } from '../../assets/LoadingStar.svg';
import './styles.scss';

const Loader = ({ isResponsive, customClassName, isLight }) => (
  <div
    className={classNames(
      'loading-icon',
      isResponsive && 'responsive-icon',
      isLight ? 'light-color' : 'dark-color',
      customClassName && customClassName
    )}
  >
    <LoadingStar />
  </div>
);

export default Loader;

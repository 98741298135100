import { Formik } from 'formik';
import emailjs from 'emailjs-com';
import { useDispatch } from 'react-redux';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../../firebases';
import { sendMessage } from '../../../store/actions';

import ContactForm from './ContactForm';
import getValidationSchema from './contactPageFormValidationSchema';

const ContactFormContainer = ({
  initialValues = {
    email: '',
    name: '',
    subject: '',
    message: '',
  },
}) => {
  emailjs.init('7Q3YIjVmtelPdwOv3');
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  if (user) {
    initialValues.email = user.email;
    initialValues.name = user.displayName;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={(form) => {
        dispatch(sendMessage(form));
      }}
    >
      {({ values }) => <ContactForm values={values} />}
    </Formik>
  );
};

export default ContactFormContainer;

import { useSelector } from 'react-redux';

import IconIllustrationHomePage from 'assets/IllustrationHomePage.svg';
import IconResponsiveHomePage from 'assets/auth/LogInPacs.svg';

import './styles.scss';
import Button from 'components/Buttons/Button';

const Hero = () => {
  const { isAuth } = useSelector((state) => state.Auth);
  const buttonOption = {
    text: isAuth ? 'Account' : 'Get started',
    path: isAuth ? '/account' : '/sign-up',
  };

  return (
    <section className="is-four-fifth hero-part">
      <div className="hero is-column">
        <div className="hero-item hero-heading cairo">
          The platform for picking Amazon products.
        </div>
        <div className="hero-item hero-text karla">
          Don’t spend days scrolling through the products deciding which one
          would be better for you. With program it takes just a few clicks.
        </div>
        <Button
          text={buttonOption.text}
          path={buttonOption.path}
          color="primary"
        />
      </div>
      <div className="hero-img-container align-start">
        <img
          src={IconIllustrationHomePage}
          alt="Abstract illustration"
          className="hero-img"
        />
      </div>
      <div className="hero-img-responsive align-start">
        <img src={IconResponsiveHomePage} alt="" className="hero-img-hidden" />
      </div>
    </section>
  );
};

export default Hero;

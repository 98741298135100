import { put, takeEvery } from 'redux-saga/effects';

import { proceedCheckoutFunc } from '../../utils/proceedCheckoutFunc';
import { API_ERROR } from '../auth/actionTypes';
import { proceedCheckoutSuccess } from './actions';
import { PROCEED_CHECKOUT } from './actionTypes';

function* proceedCheckoutSaga({ payload }) {
  try {
    yield proceedCheckoutFunc(
      payload.priceParsed,
      payload.lvlParsed,
      payload.uid,
      payload.picked
    );
    yield put(proceedCheckoutSuccess());
  } catch (error) {
    yield put(API_ERROR());
  }
}

function* checkoutSaga() {
  yield takeEvery(PROCEED_CHECKOUT, proceedCheckoutSaga);
}

export default checkoutSaga;

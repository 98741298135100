import { API_ERROR } from '../auth/actionTypes';
import { GET_USER_INFO, GET_USER_INFO_SUCCESS } from './actionTypes';

const initialState = {
  authProvider: null,
  lastPurchaseDate: null,
  purchaseType: null,
  subLvl: null,

  error: null,
  loading: false,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,

        authProvider: action.payload.authProvider,
        lastPurchaseDate: action.payload.lastPurchaseDate,
        purchaseType: action.payload.purchaseType,
        subLvl: action.payload.subLvl,

        loading: false,
      };
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default user;

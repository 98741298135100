import { API_ERROR } from '../auth/actionTypes';
import {
  ADD_PROXY,
  ADD_PROXY_SUCCESS,
  GET_PROXY,
  GET_PROXY_SUCCESS,
  EDIT_PROXY,
  EDIT_PROXY_SUCCESS,
} from './actionTypes';

const initialState = {
  list: [],
  error: null,
  loading: false,
};

const redactors = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROXY:
      return {
        ...state,
        loading: true,
      };
    case ADD_PROXY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_PROXY:
      return {
        ...state,
        loading: true,
      };
    case EDIT_PROXY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_PROXY:
      return {
        ...state,
        loading: true,
      };
    case GET_PROXY_SUCCESS:
      return {
        ...state,
        list: action.payload,
        loading: false,
      };
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default redactors;

import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

const classes = 'header-href karla f-20 black no-decorators';
const links = [
  {
    to: '#work',
    text: 'How it works?',
  },
  {
    to: '#appearance',
    text: 'Appearance',
  },
  {
    to: '#price',
    text: 'Costs',
  },
  {
    to: '/faq',
    text: 'FAQ',
    isRouterLink: true,
  },
];

const MainPageLinks = ({ onClick, isAuth }) => (
  <>
    {links.map(({ to, text, isRouterLink }) =>
      isRouterLink ? (
        <Link
          key={`sidebar-link-${v4()}`}
          onClick={onClick}
          to={to}
          className={classes}
        >
          {text}
        </Link>
      ) : (
        <a
          key={`sidebar-link-${v4()}`}
          onClick={onClick}
          href={to}
          className={classes}
        >
          {text}
        </a>
      )
    )}
    {isAuth ? (
      <Link
        onClick={onClick}
        to="/account"
        className="header-href karla f-20 black no-decorators"
      >
        Account
      </Link>
    ) : (
      <Link
        onClick={onClick}
        to="/log-in"
        className="header-href karla f-20 black no-decorators"
      >
        Log in
      </Link>
    )}
  </>
);

export default MainPageLinks;

import { Formik } from 'formik';
import { connect, useDispatch } from 'react-redux';

import { addProxy, editProxy } from '../../../store/actions';
import getValidationSchema from './proxyFormValidationSchema';

import ProxyForm from './ProxyForm';
import { ProxyStatus } from '../../../enums/proxy.enum';

const ProxyFormContainer = ({ initialValues, loading, type }) => {
  const dispatch = useDispatch();
  const submitOnType = (form) => {
    delete form['addressSearch'];

    if (type === 'Add') {
      dispatch(addProxy(form));
      return;
    }

    dispatch(editProxy(form));
    return;
  };

  const validationSchema = getValidationSchema({ type });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(form) => submitOnType(form)}
    >
      {({ setFieldValue, values }) => (
        <ProxyForm
          type={type}
          values={values}
          loading={loading}
          setFieldValue={setFieldValue}
        />
      )}
    </Formik>
  );
};

function mapStateToProps(state, { type }) {
  const { loading } = state.Proxy;

  const initialValues = {
    address: '',
    port: '',
    login: '',
    pwd: '',
    status: ProxyStatus.ACTIVE,
    ...(type === 'Edit' && { addressSearch: '' }),
  };

  return {
    initialValues,
    loading,
    type,
  };
}

export default connect(mapStateToProps)(ProxyFormContainer);

import { db } from '..';
import {
  doc,
  query,
  limit,
  setDoc,
  getDoc,
  getDocs,
  collection,
  where,
} from 'firebase/firestore';

import {
  IArticleDoc,
  IArticlePayload,
} from '../../interfaces/articles.interface';

export const addArticleDoc = async ({
  textarea,
  type,
  title,
  id,
  date,
  collection,
}: IArticlePayload): Promise<void> => {
  const articleDoc = {
    title,
    textarea,
    type,
    id,
    date,
  } as IArticleDoc;

  await setDoc(doc(db, collection, title), articleDoc);
};

export const editArticleDoc = async ({
  type,
  title,
  textarea,
  collection,
}: IArticlePayload): Promise<void> => {
  const articleDoc = {
    type,
    title,
    textarea,
  } as IArticleDoc;

  await setDoc(doc(db, collection, title), articleDoc);
};

export const getArticleDoc = async ({
  take,
  title,
  collectionName,
  filter,
  filterValue,
}: {
  title: string;
  take: number;
  collectionName: string;
  filter: string;
  filterValue: string;
}) => {
  if (!title) {
    let q = query(collection(db, collectionName), limit(take));

    if (filter && filterValue && filterValue !== 'all')
      q = query(q, where(filter, '==', filterValue));

    return await getDocs(q)
      .then((data) =>
        data.docs.map((doc) => {
          const data = doc.data() as IArticleDoc;

          return { value: data.title, label: data.title, ...data };
        })
      )
      .catch((err) => {
        throw err;
      });
  }

  const docSnap = await getDoc(doc(db, collectionName, title));

  if (docSnap.exists()) {
    return docSnap.data();
  }

  throw Error('Article not found');
};

import { useEffect, useReducer, useState, useMemo } from 'react';
import { useDebouncedValue } from '@mantine/hooks';

import optionsFetchReducer from '../reducer';
import {
  FETCH_REQUEST,
  FETCH_REQUEST_FAILURE,
  FETCH_REQUEST_SUCCESS,
} from '../actions';

const initialState = {
  isLoading: false,
  isError: false,
  options: [],
};

const includeByCondition = ({ condition, ...rest }) => ({
  ...(condition && { ...rest }),
});
const sortByLabel = (a, b) => {
  const aLabel = a.label.toLowerCase();
  const bLabel = b.label.toLowerCase();
  if (aLabel < bLabel) {
    return -1;
  }
  if (aLabel > bLabel) {
    return 1;
  }
  return 0;
};

export default function useTypeAheadFetchHook({
  optionsFetchPromise,
  selectedOption,
  filterOptions,
  selectOptionsMapper,
  selectOptionsSort = sortByLabel,
}) {
  const [state, dispatch] = useReducer(optionsFetchReducer, initialState);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [searchText, setSearchText] = useState('');

  const [debounced] = useDebouncedValue(searchText, 400);

  const defaultFilterOptions = useMemo(
    () => ({
      ...includeByCondition({ condition: Boolean(debounced), debounced }),
    }),
    [debounced]
  );

  useEffect(() => {
    let isComponentUnmounted = false;
    const fetchData = async () => {
      let selectOptions = [];
      dispatch({ type: FETCH_REQUEST });

      const response = await optionsFetchPromise({
        ...defaultFilterOptions,
        ...includeByCondition({
          condition: selectedOption,
          selectedOption,
        }),
        ...includeByCondition({
          condition: filterOptions,
          ...filterOptions,
        }),
      });

      const data = response?.hasOwnProperty('list') ? response.list : response;

      if (selectOptionsSort !== null) {
        selectOptions = data
          ? data.map(selectOptionsMapper).sort(selectOptionsSort)
          : [];
      }
      selectOptions = data;

      if (!isComponentUnmounted) {
        dispatch({ type: FETCH_REQUEST_SUCCESS, payload: selectOptions });
      }
      if (isFirstTime) {
        setIsFirstTime(false);
      }

      if (!isComponentUnmounted) {
        dispatch({ type: FETCH_REQUEST_FAILURE });
      }
    };
    fetchData();
    return () => {
      isComponentUnmounted = true;
    };
  }, [filterOptions, defaultFilterOptions, selectedOption]);

  const handleInputChange = (value) => setSearchText(value.trim());

  return { state, handleInputChange };
}

import * as Yup from 'yup';

const getValidationSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .required('Email is required'),
    name: Yup.string().required('Your name is required'),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Text message is required')
  });
};

export default getValidationSchema;

import { useLocation } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const isMainPage = pathname === '/';

  return (
    <div className="is-column align-center layout">
      <Header />
      {children}
      {isMainPage && <Footer />}
    </div>
  );
};

export default Layout;

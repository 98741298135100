import './styles.scss';

const incomeCards = [
  {
    title: 'Monthly users',
    profit: '2,120',
    up: '120',
  },
  {
    title: 'Monthly income',
    profit: '10 000,12$',
    up: '87',
  },
];

const IncomeCards = ({ data }) => {
  const { title, profit, up } = data;

  return (
    <div className="income-card rounded">
      <span className="title karla">{title}</span>
      <span className="profit karla">{profit}</span>
      <span className="up karla">
        <span className="percent karla">{up}%</span>up since last month
      </span>
    </div>
  );
};

const IncomeStatistics = () => {
  return (
    <div className="income-container">
      <span className="title karla rounded">Statistics </span>

      <div className="income-cards">
        {incomeCards.map((data) => (
          <IncomeCards key={'income-card' + data.title} data={data} />
        ))}
      </div>
    </div>
  );
};
export default IncomeStatistics;

import PasswordResetFormContainer from '../../../components/Forms/PasswordResetForm/PasswordResetFormContainer';
import './styles.scss';

const PasswordResetPage = () => (
  <section className="password-reset is-four-fifth">
    <div className="password-reset-heading cairo">Reset password</div>
    <PasswordResetFormContainer />
  </section>
);

export default PasswordResetPage;

import { combineReducers } from 'redux';

//auth
import Auth from './auth/reducer';

//user
import User from './user/reducer';

//articles
import Article from './articles/reducer';

//proxy
import Proxy from './proxy/reducer';

//contact
import Contact from './contact/reducer';

//checkout
import Checkout from './checkout/reducer';

const rootReducer = combineReducers({
  Auth,
  User,
  Proxy,
  Contact,
  Article,
  Checkout,
});

export default rootReducer;

import { put, takeEvery } from 'redux-saga/effects';

import {
  addArticleDoc,
  editArticleDoc,
  getArticleDoc,
} from '../../firebases/docs';
import { toastSuccess, toastWarning } from '../../utils/toasts';

import {
  addArticleSuccess,
  editArticleSuccess,
  getArticleSuccess,
} from './actions';
import { apiError } from '../actions';
import { ADD_ARTICLE, EDIT_ARTICLE, GET_ARTICLE } from './actionTypes';

function* addArticleSaga({ payload }) {
  try {
    yield addArticleDoc(payload);
    yield put(addArticleSuccess());

    toastSuccess('New article added successfully');
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError(error.message));
  }
}

function* editArticleSaga({ payload }) {
  try {
    yield editArticleDoc(payload);
    yield put(editArticleSuccess());

    toastSuccess('Article edited successfully');
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError());
  }
}

function* getArticleSaga({ payload }) {
  try {
    const article = yield getArticleDoc(payload);

    yield put(getArticleSuccess(article));
  } catch (error) {
    toastWarning(error.message);
    yield put(apiError());
  }
}

function* ArticleSaga() {
  yield takeEvery(ADD_ARTICLE, addArticleSaga);
  yield takeEvery(EDIT_ARTICLE, editArticleSaga);
  yield takeEvery(GET_ARTICLE, getArticleSaga);
}

export default ArticleSaga;

import * as Yup from 'yup';

const getValidationSchema = ({ type }) => {
  return Yup.object().shape({
    address: Yup.string()
      .required('Address is required')
      .max('12', 'Address is too long')
      .min('12', 'Address is too short'),
    login: Yup.string().required('Login is required'),
    port: Yup.string()
      .required('Port is required')
      .max('5', 'Address is too long')
      .min('5', 'Address is too short'),
    pwd: Yup.string().required('Passwords is required'),
    status: Yup.string().required('status is required'),

    ...(type === 'Edit' && {
      addressSearch: Yup.string()
        .required('Address is required to search')
        .max('12', 'Address is too long')
        .min('12', 'Address is too short'),
    }),
  });
};

export default getValidationSchema;

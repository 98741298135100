import { useSelector } from 'react-redux';

import Button from '../../../../../components/Buttons/Button';

import IconCardStar from '../../../../../assets/cards/CardStar.svg';
import IconSnakeVertical from '../../../../../assets/SnakeVertical.svg';
import IconCardOnePac from '../../../../../assets/cards/CardOnePac.svg';
import IconCardPacHalf from '../../../../../assets/cards/CardPacHalf.svg';
import IconSnakeHorizontal from '../../../../../assets/SnakeHorizontal.svg';
import IconCardOnePacSmall from '../../../../../assets/cards/CardOnePacSmall.svg';
import IconCardOnePacHidden from '../../../../../assets/cards/CardOnePacHidden.svg';

import './styles.scss';
import { useState } from 'react';

const Work = () => {
  const { isAuth } = useSelector((state) => state.Auth);
  const buttonOptions = {
    text: isAuth ? 'Account' : 'Sign Up',
    path: isAuth ? 'account' : 'sign-up',
  };

  const animationImages = [IconCardOnePacSmall, IconCardOnePacHidden];

  const [initialInterval, setInitialInterval] = useState(0);
  const [functionalInterval, setFunctionalInterval] = useState(null);

  const setIntervalFunc = () => {
    setFunctionalInterval(
      setInterval(() => {
        setInitialInterval((v) => (v === 1 ? 0 : v + 1));
      }, 750)
    );
  };

  const clearIntervalFunc = () => {
    clearInterval(functionalInterval);
    setInitialInterval(0);
  };

  return (
    <section className="work-part is-four-fifth is-column" id="work">
      <div className="work-heading cairo">How does it work?</div>
      <div className="work-cards">
        <div
          className="work-card work-card-one"
          onMouseEnter={() => setIntervalFunc()}
          onMouseLeave={() => clearIntervalFunc()}
        >
          <div className="work-card-container">
            <div className="work-card-content">
              <div className="work-card-heading cairo">Register</div>
              <div className="work-card-text karla">
                Fill in a simple form and move to the next step
              </div>
            </div>
            <div className="work-button-container">
              <Button text={buttonOptions.text} path={buttonOptions.path} />
            </div>
          </div>
          <img src={IconCardOnePac} alt="" className="img-pac" />
          <img
            src={animationImages[initialInterval]}
            alt=""
            className="img-pac-small"
          />
        </div>
        <div className="work-card work-card-two">
          <div className="work-card-container">
            <div className="work-card-heading cairo">Download the program</div>
            <div className="work-card-text karla">
              Program is compatible with both Windows and MacOS
            </div>
          </div>
          <img src={IconSnakeVertical} alt="" className="img-snake-vert" />
          <img src={IconSnakeHorizontal} alt="" className="img-snake-hor" />
        </div>
        <div className="work-card work-card-three">
          <div className="work-card-container">
            <div className="work-card-heading cairo">
              Choose several criteria
            </div>
            <div className="work-card-text karla">
              Depending on the level of your subscription, you will have some
              criteria for the products. Choose some you like the most and we
              got the rest
            </div>
          </div>
          <img src={IconCardStar} alt="" className="img-star" />
          <img src={IconCardPacHalf} alt="" className="img-pac-half" />
        </div>
      </div>
    </section>
  );
};

export default Work;

import {
  LOGIN,
  LOGIN_SUCCESS,
  REGISTER,
  REGISTER_SUCCESS,
  LOGOUT,
  LOGOUT_SUCCESS,
  API_ERROR,
  AUTH_UPDATE,
  AUTH_UPDATE_SUCCESS,
  UPDATE_CREDENTIALS,
  UPDATE_CREDENTIALS_SUCCESS,
} from './actionTypes';

export const login = (user, navigate, isEditProfileRedirect) => {
  return {
    type: LOGIN,
    payload: { user, navigate, isEditProfileRedirect },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const register = (user) => {
  return {
    type: REGISTER,
    payload: user,
  };
};

export const registerSuccess = (user) => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  };
};

export const updateCredentials = (user) => {
  return {
    type: UPDATE_CREDENTIALS,
    payload: user,
  };
};

export const updateCredentialsSuccess = (user) => {
  return {
    type: UPDATE_CREDENTIALS_SUCCESS,
    payload: user,
  };
};

export const updateToken = (token) => {
  return {
    type: AUTH_UPDATE,
    payload: token,
  };
};

export const updateTokenSuccess = (token) => {
  return {
    type: AUTH_UPDATE_SUCCESS,
    payload: token,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const logoutSuccess = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};

import IconPriceTwo from '../../../../../assets/price/PriceTwo.svg';
import IconPriceOne from '../../../../../assets/price/PriceOne.svg';
import IconPriceThree from '../../../../../assets/price/PriceThree.svg';

export const cardList = [
  {
    icon: IconPriceOne,
    color: 'dark-blue',
    iconWidth: 'small',
    title: 'Simple',
    subtitle: '$25 monthly',
    list: [
      {
        title: 'Price filter',
      },
      {
        title: '20 000 products per day',
        detailsText:
          'The amount of products program can look through in search of ones that will match your criteria.',
      },
      {
        title: 'Sequential category check',
        detailsText:
          'It means that the first category you choose will be looked through and only if there is no products left in this category program will autimatically move on to the second one and so on.',
      },
      {
        title: 'Presetted BSR',
        detailsText:
          'Products will be chosen based on the optimal BSR presetted by the program. It means that you cannot change it.',
      },
    ],
  },

  {
    icon: IconPriceTwo,
    title: 'Medium',
    subtitle: '$55 monthly',
    color: 'yellow',
    iconWidth: 'medium',
    customClass: 'mr',
    list: [
      {
        title: 'Price filter',
      },
      {
        title: '50 000 products per day',
        detailsText:
          'The amount of products program can look through in search of ones that will match your criteria.',
      },
      {
        title: 'Parallel category check',
        detailsText:
          'It means that all the categories youve chosen will be looked through at the same time so that you get products from all of them.',
      },
      {
        title: 'BSR filter',
        detailsText: 'You can choose general BSR for all the categories.',
      },
      {
        title: 'Parency check',
        detailsText:
          'Program will check if there is a parent on the product page. Details about what is parent you can find at FAQ page.',
      },
      {
        title: 'Two levels of system load',
        detailsText:
          'You can choose the level of computer load to optimize your PC.',
      },
    ],
  },
  {
    icon: IconPriceThree,
    title: 'Premium',
    subtitle: '$95 montly',
    color: 'green',
    iconWidth: 'standard',

    list: [
      {
        title: 'Price filter',
      },
      {
        title: '100 000 products per day',
        detailsText:
          'The amount of products program can look through in search of ones that will match your criteria.',
      },
      {
        title: 'Parallel category check',
        detailsText:
          'It means that all the categories youve chosen will be looked through at the same time so that you get products from all of them.',
      },
      {
        title: 'Parency check',
        detailsText:
          'Program will check if there is a parent on the product page. Details about what is parent you can find at FAQ page.',
      },
      {
        title: 'BSR filter for each category',
        detailsText: 'You can set BSR for each category you have chosen.',
      },
      {
        title: 'Brands filter',
        detailsText:
          'You can choose both which brand to skip while serching for ones that match your criteria. Also you can specify some brands to be chosen only, which means all the other brands will be skipped.',
      },
      {
        title: 'Three levels of system load',
        detailsText:
          'You can choose the level of computer load to optimize your PC.',
      },
      {
        title: 'Distributor check',
        detailsText:
          'Program will check if the seller of the product does not match brand listed on the product page.',
      },
      {
        title: 'Amazon as a seller check',
        detailsText: 'Program will check if Amazon sells a product.',
      },
    ],
  },
];

import { Link } from 'react-router-dom';
import Loader from '../../Loader';
import './styles.scss';

function isLoader({ loading, text }) {
  if (loading) {
    return <Loader isLight />;
  }

  return text;
}

const Button = ({
  path,
  text,
  onClick,
  loading,
  color = 'default',
  notSubmittable,
}) => (
  <>
    {path ? (
      <Link
        to={path}
        className={`button btn-${color} no-decorators s-font karla`}
      >
        {isLoader({ loading, text })}
      </Link>
    ) : notSubmittable ? (
      <div
        onClick={onClick && (() => onClick())}
        className={` button btn-${color} no-decorators s-font karla${
          loading ? 'btn-disabled' : ''
        }`}
        disabled={loading}
      >
        {isLoader({ loading, text })}
      </div>
    ) : (
      <button
        onClick={onClick && (() => onClick())}
        type="submit"
        className={`button btn-${color} no-decorators s-font karla`}
      >
        {isLoader({ loading, text })}
      </button>
    )}
  </>
);

export default Button;

import RoleManager from '../../components/Layout/RoleManager';
import IncomeStatistics from './components/IncomeStatistics';
import Navigation from './components/Navigation';

import './styles.scss';

const AdminPage = () => (
  <RoleManager>
    <section className="admin-panel">
      <IncomeStatistics />
      <Navigation />
    </section>
  </RoleManager>
);

export default AdminPage;

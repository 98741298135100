import { SEND_MESSAGE, SEND_MESSAGE_SUCCESS } from './actionTypes';

export const sendMessage = (payload) => {
  return {
    type: SEND_MESSAGE,
    payload,
  };
};

export const sendMessageSuccess = (payload) => {
  return {
    type: SEND_MESSAGE_SUCCESS,
    payload,
  };
};

import { PROCEED_CHECKOUT, PROCEED_CHECKOUT_SUCCESS } from './actionTypes';
import { API_ERROR } from '../auth/actionTypes';

const initialState = {
  error: false,
  loading: false,
};

const checkout = (state = initialState, action) => {
  switch (action.type) {
    case PROCEED_CHECKOUT:
      return {
        ...state,
        loading: true,
      };
    case PROCEED_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default checkout;

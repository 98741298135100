import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import getValidationSchema from './checkoutPageFormValidationSchema';
import { LevelLowLabel, LevelPrice } from '../../../enums/lvl.enum';
import { proceedCheckout } from '../../../store/checkout/actions'

import CheckoutForm from './CheckoutForm';

const CheckoutFormContainer = ({
  initialValues = {
    picked: 'crypto',
  },
  lvl,
  uid,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Checkout)
  let lvlParsed = LevelLowLabel[lvl];
  let priceParsed = Number(LevelPrice[lvl]);

  return (
    <Formik
      enableReinitialize
      onSubmit={({ picked }) => dispatch(proceedCheckout({priceParsed, lvlParsed, uid, picked}))}
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
    >
      {({ values }) => <CheckoutForm values={values} loading={loading} />}
    </Formik>
  );
};

export default CheckoutFormContainer;

import ProxyFormContainer from '../../../../../../components/Forms/ProxyForm/ProxyFormContainer';
import './styles.scss';

const ProxyRedactor = ({ type }) => {
  return (
    <div className="proxy-redactor-container">
      <ProxyFormContainer type={type} />
    </div>
  );
};

export default ProxyRedactor;

import MainPageLinks from './MainPageLinks';
import './styles.scss';

const Sidebar = ({ onClick, isOpen, isAuth }) => {
  return (
    <section className={`sidebar ${isOpen && 'active'}`}>
      <div className="is-column align-start sidebar-links">
        <MainPageLinks onClick={onClick} isAuth={isAuth} />
      </div>
    </section>
  );
};

export default Sidebar;

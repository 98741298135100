import { useSelector } from 'react-redux';
import IconSearch from '../../../../../assets/Search.svg';

const Searcher = ({ setFilteredArticles }) => {
  const { article } = useSelector((state) => state.Article);

  function filterArticles(articles, value) {
    if (!value) {
      setFilteredArticles(article);
    } else {
      const temporaryArticles = articles.filter((article) =>
        article.title.toLowerCase().includes(value.toLowerCase())
      );
      if (temporaryArticles.length !== 0) {
        setFilteredArticles(temporaryArticles);
      } else {
        setFilteredArticles(null);
      }
    }
  }
  return (
    <div className="faq-search-container">
      <label htmlFor="faq-search" className="faq-search-label">
        <img src={IconSearch} alt="" className="faq-search-icon" />
      </label>
      <input
        id="faq-search"
        type="text"
        placeholder="Search..."
        onChange={(value) => filterArticles(article, value.target.value)}
        className="faq-search-field karla"
      />
    </div>
  );
};
export default Searcher;

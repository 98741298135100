import classNames from 'classnames';
import { ErrorMessage, Field } from 'formik';
import { useState } from 'react'

import FormikError from '../../FormikError/index';
import IconEyeOpened from '../../../../assets/auth/EyeOpened.svg'
import IconEyeClosed from '../../../../assets/auth/EyeClosed.svg'
import './styles.scss';

const FormField = ({
  component,
  name,
  label,
  type,
  id,
  customClassName,
  divCustomClassName,
  errorCustomClassName,
  ...restProps
}) => {
const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
  <div className={classNames(`input-container is-column align-center ${divCustomClassName}`)}>
      <label className="content-start karla" htmlFor={id}>{label}</label>
    <div className='field-container'>
      <Field
        name={name}
        id={id}
        {...(component && { component })}
        {...(type && { type })}
        {...restProps}
        className={classNames('field karla', customClassName)}
        type={type?type:(passwordShown? 'text' : 'password')}
        />
      {!type && 
        (passwordShown? 
          (
            <img src={IconEyeOpened} alt='Show password' onClick={() => togglePasswordVisiblity()} className='field-eye'/>
          ) : (
            <img src={IconEyeClosed} alt='Show password' onClick={() => togglePasswordVisiblity()} className='field-eye'/>
          )
        )
      }
      </div>
    <ErrorMessage component={FormikError} name={name} className={errorCustomClassName} />
  </div>
  )};

export default FormField;

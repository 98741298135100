import Select from 'react-select';

const getOptionValue = (option) => (option ? option.value : null);
const getMultiOptionValues = (options) => (options ? options : null);

const handleChange =
  (form, field, isMulti, customSetFieldValue, otherValueAutoSet) =>
  (option) => {
    const fieldValue = isMulti
      ? getMultiOptionValues(option)
      : getOptionValue(option);
    form.setFieldValue(field.name, fieldValue);

    customSetFieldValue && customSetFieldValue(field.name, option.value);

    if (otherValueAutoSet) {
      otherValueAutoSet.forEach((element) => {
        customSetFieldValue && customSetFieldValue(element, option[element]);
      });
    }
  };

const colourStyles = {
  container: (styles) => ({
    ...styles,
    width: '85%',
  }),
  control: (styles, { isFocused, isSelected }) => ({
    ...styles,

    paddingLeft: '20px',
    borderRadius: '10px',
    border:
      isFocused || isSelected
        ? '3px solid #04ba70'
        : '3px solid rgb(25, 25, 25)',

    '&:hover': {
      border: '3px solid #04ba70',
    },
  }),
  option: (styles, { isDisabled, isOptionFocused }) => {
    return {
      ...styles,
      color: '#FFFFFF',
      backgroundColor: isOptionFocused ? '#04ba70' : '#04ba7040',
      cursor: isDisabled ? 'not-allowed' : 'default',
      '&:hover': {
        backgroundColor: '#04ba70',
      },
    };
  },
};

const SelectFormField = ({
  id,
  field,
  form,
  onBlur,
  options = [],
  isMulti = false,
  disabled = false,
  isLoading = false,
  isClearable = false,
  placeholder,
  filterOption,
  maxMenuHeight,
  onInputChange,
  selectedOption,
  otherValueAutoSet,
  customSetFieldValue,
}) => {
  const getValue = () => {
    if (options && options.length) {
      return isMulti && field.value
        ? field.value.map((v) => ({
            value: v.id || v.value,
            label: v.name || v.label,
          }))
        : options.find((o) => o.value === field.value) || '';
    } else {
      return isMulti ? [] : '';
    }
  };

  return (
    <Select
      inputId={id}
      name={field.name}
      value={selectedOption || getValue()}
      onChange={handleChange(
        form,
        field,
        isMulti,
        customSetFieldValue,
        otherValueAutoSet
      )}
      onBlur={onBlur}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      isLoading={isLoading}
      onInputChange={onInputChange}
      isClearable={isClearable}
      isDisabled={disabled}
      maxMenuHeight={maxMenuHeight}
      {...(filterOption ?? { filterOption })}
      menuPortalTarget={document.body}
      styles={colourStyles}
    />
  );
};

export default SelectFormField;

import { useLayoutEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { getProxy } from '../../../../../../store/actions';
import {
  ProxyStatusLabel,
  ProxyStatusColorIndication,
} from '../../../../../../enums/proxy.enum';

import Loader from '../../../../../../components/Loader';

import './styles.scss';

const ProxyList = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector((state) => state.Proxy);

  useLayoutEffect(() => {
    dispatch(getProxy({}));
  }, [dispatch]);

  return (
    <div className="proxy-manager-list">
      {loading ? (
        <Loader isResponsive />
      ) : (
        list?.map(({ address, status, port, login, pwd }) => (
          <div key={`proxy-${address}`} className="proxy">
            <div className="proxy-header">
              <div className="address">Address</div>
              <div className="status">Status</div>
              <div className="port">Port</div>
              <div className="login">Login</div>
              <div className="pwd">PWD</div>
            </div>
            <div className="proxy-settings">
              <div className="address">{address}</div>
              <div
                className={classNames(
                  'status',
                  ProxyStatusColorIndication[status]
                )}
              >
                {ProxyStatusLabel[status]}
              </div>
              <div className="port">{port}</div>

              <div className="login">{login}</div>
              <div className="pwd">{pwd}</div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ProxyList;

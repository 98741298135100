import ContactFormContainer from '../../../components/Forms/ContactForm/ContactFormContainer';

import './styles.scss';

const ContactPage = () => {
  return (
    <section className="contact">
      <div className="contact-header content-center cairo">Hit us up</div>
      <ContactFormContainer />
    </section>
  );
};

export default ContactPage;

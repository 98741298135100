import { Form } from "formik";
import { useSelector } from "react-redux";

import Button from "../../Buttons/Button";
import { FormField } from "../../Formik/Fields";

import "./styles.scss";

const formFields = () =>
  [
    {
      id: "email",
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "Your email",
      show: true,
    },
  ].filter(({ show }) => show);

const renderFormField = ({ show, ...fieldProps }) => (
  <FormField key={fieldProps.name} {...fieldProps} />
);

const PasswordResetForm = ({ initialValues }) => {
  const { loading } = useSelector((state) => state.Auth);

  return (
    <Form
      className="password-reset-form is-column align-center"
      autoComplete="off"
    >
      {formFields({
        initialValues,
      }).map(renderFormField)}

      <div className="full-width content-center">
        <Button text="Reset password" loading={loading} />
      </div>
    </Form>
  );
};

export default PasswordResetForm;

import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../../firebases';
import { updateCredentials } from '../../../store/actions';
import getValidationSchema from './accountFormValidationSchema';

import AccountForm from './AccountForm';

const AccountFormContainer = ({
  initialValues = {
    name: '',
    email: '',
    password: '',
  },
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  if (user) {
    initialValues.email = user?.email;
    initialValues.name = user?.displayName;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={({ email, name, password }) =>
        dispatch(updateCredentials({ email, name, password, navigate }))
      }
    >
      {() => <AccountForm initialValues={initialValues} />}
    </Formik>
  );
};

export default AccountFormContainer;

import { Link } from "react-router-dom";

import IconLogo from "../../../assets/Logo.svg";

import "./styles.scss";

const Footer = () => {
  return (
    <section className="footer is-column">
      <div className="footer-line" />
      <div className="footer-content content-between is-nine-tenth is-offset-one-twenty karla">
        <div className="footer-rights align-center">® All rights reserved</div>
        <div className="footer-logo-container align-center">
          <img src={IconLogo} alt="GSINS Logo" className="footer-logo" />
        </div>
        <div className="footer-links footer-buttons is-row align-center">
          <Link to="/faq" className="footer-link faq no-decorators black">
            FAQ
          </Link>
          <Link
            to="/contact"
            className="footer-link hit-us no-decorators black"
          >
            Hit us up
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;

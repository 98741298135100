import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const SubLvlDivider = () => {
  const { subLvl } = useSelector((state) => state.User);

  return (
    <Link
      to="/subscriptions"
      className="account-button white no-decorators karla"
    >
      {subLvl ? 'Change your plan' : 'Choose your plan'}
    </Link>
  );
};

const AuthSubLvlConditionalBtnRender = () => {
  const { isAuth } = useSelector((state) => state.Auth);

  if (isAuth) return <SubLvlDivider />;

  return (
    <Link to="/log-in" className="account-button white no-decorators">
      Log in to see subscription plan
    </Link>
  );
};

export default AuthSubLvlConditionalBtnRender;

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendPasswordResetEmail } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from '../../../firebases';
import { toastSuccess, toastWarning } from '../../../utils/toasts';
import { firebaseErrFormatter } from '../../../utils/firebaseErrorFormatter';

import Button from '../../../components/Buttons/Button';
import AccountFormContainer from '../../../components/Forms/AccountForm/AccountFormContainer';

import './styles.scss';

const resetPassword = (email, navigate) => {
  if (email) {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toastSuccess('Password reset link sent');
        navigate('/log-in');
      })
      .catch(() => {
        toastWarning(firebaseErrFormatter('user-not-found'));
      });
  }
};

const EditProfilePage = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const resetPasswordCallback = useCallback(
    (navigate) => resetPassword(user?.email, navigate),
    [user?.email]
  );

  return (
    <section className="edit-profile">
      <div className="edit-profile-heading cairo">Edit profile</div>
      <AccountFormContainer />
      <Button
        color="trans"
        text="Get reset password message"
        onClick={() => resetPasswordCallback(navigate)}
      />
    </section>
  );
};

export default EditProfilePage;

import { useSelector } from 'react-redux';
import { LevelLabel, LevelLowLabel } from 'enums/lvl.enum';

const AccountSubscriptionInfo = () => {
  const { subLvl, lastPurchaseAmount } = useSelector((state) => state.User);
  return (
    <div className="account-subtitle is-row karla">
      <div className={`level-${LevelLowLabel[subLvl]}`} />
      <span className="account-subtitle-text">
        {LevelLabel[subLvl] && LevelLabel[subLvl]}
        {!subLvl && lastPurchaseAmount && 'Subscription timed out'}
      </span>
    </div>
  );
};

export default AccountSubscriptionInfo;

import PropTypes from 'prop-types';
import SelectFormFieldFormik from '../../SelectFormField';
import useTypeAheadFetchHook from '../../../../../hooks/useTypeAheadFirebaseFetchHook';

const AutocompleteField = (props) => {
  const {
    field,
    dataFetchFilterOptions,
    optionsMapper,
    optionsSort,
    fetchDataCall,
    isClearable,
    setInitialDefaultOption,
    setFieldValue,
  } = props;
  const {
    state: { isLoading, isError, options },
    handleInputChange,
  } = useTypeAheadFetchHook({
    optionsFetchPromise: fetchDataCall,
    selectedOption: field.value,
    filterOptions: dataFetchFilterOptions,
    selectOptionsMapper: optionsMapper,
    selectOptionsSort: optionsSort,
  });

  if (isError) {
    console.warn('Error on autocomplete loading');
  }

  if (
    setInitialDefaultOption &&
    !isClearable &&
    !field.value &&
    options.length
  ) {
    const { setFieldValue } = props.form;

    setFieldValue(field.name, options[0].value);
  }

  return (
    <SelectFormFieldFormik
      {...props}
      options={options}
      isLoading={isLoading}
      onInputChange={handleInputChange}
      customSetFieldValue={setFieldValue}
    />
  );
};

AutocompleteField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  selectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataFetchFilterOptions: PropTypes.object,
  fetchDataCall: PropTypes.func.isRequired,
  optionsMapper: PropTypes.func,
  placeholder: PropTypes.string,
};

export default AutocompleteField;

import { doc, getDoc } from 'firebase/firestore';
import { db } from '..';

export const checkForAdminRoots = async (uid: string): Promise<boolean> => {
  const docSnap = await getDoc(doc(db, 'admins', uid));

  if (docSnap.exists()) {
    return true;
  }

  return false;
};

import Button from '../../../../../components/Buttons/Button';
import './styles.scss';

const AccountCard = ({ title, imgH, imgV }) => (
  <div className="account-card">
    <div className="account-card-container is-column content-between">
      <div className="card-title karla">{title}</div>
      <Button text="Download" />

      <img src={imgH} alt="horizontal-snake" className="horizontal-snake" />
      <img src={imgV} alt="vertical-snake" className="vertical-snake" />
    </div>
  </div>
);

export default AccountCard;

import { toastWarning } from './toasts';

export const proceedCheckoutFunc = async (
  priceParsed: number,
  lvlParsed: string,
  uid: string,
  picked: string
) => {
  //TODO MOVE ALL REQUEST CONFIGS TO ENV
  if (picked === 'crypto') {
    await new Promise((resolve) => setTimeout(resolve, 800));
    let myHeaders = new Headers();
    myHeaders.append('x-api-key', '2T2B0WP-RZYMR67-JHD70HX-W3GE4TS');
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      price_amount: `${priceParsed}`,
      price_currency: 'usd',
      order_description: `Payment for ${lvlParsed} subscription plan`,
      ipn_callback_url: `https://us-central1-gsins-auth.cloudfunctions.net/addPaymentStatus?subLvl=${lvlParsed}&uid=${uid}`,
      success_url: 'https://nowpayments.io',
      cancel_url: 'https://nowpayments.io',
    });

    fetch('https://api.nowpayments.io/v1/invoice', {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    })
      .then((response) => response.text())
      .then((result) => {
        window.open(JSON.parse(result).invoice_url, '_blank');
        console.log(JSON.parse(result));
      })
      .catch((error) => console.log('error', error));
  } else if (picked === 'paypal') {
    toastWarning('Still under development. Stay tuned!');
  } else {
    toastWarning('Oops, something went wrong! Try again!');
  }
};

import { v4 } from 'uuid';
import IconQuestionMark from '../../../../../../assets/QuestionMark.svg';
import './styles.scss';

const PriceCard = ({
  icon,
  title,
  subtitle,
  list,
  color,
  iconWidth,
  customClass,
}) => (
  <div className={`price-card is-column align-center ${customClass}`}>
    <img src={icon} alt="" className={`card-icon icon-width-${iconWidth}`} />
    <div className={`price-subtitle color-${color} karla-bold`}>{subtitle}</div>
    <div className="price-title karla-bold">{title}</div>
    <ul className="price-list karla">
      {list.map(({ title, detailsText }) => (
        <div key={v4()} className="price-card-container">
          <li className={`price-list-item color-${color}`}>
            <span className="price-list-text">{title}</span>
          </li>
          {detailsText && (
            <div className="details">
              <div className="details-icon-container">
                <img src={IconQuestionMark} alt="" className="details-icon" />

                <div className="details-content">
                  <div className="details-container">
                    <div className="details-text karla">{detailsText}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </ul>
  </div>
);

export default PriceCard;

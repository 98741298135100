import {
  ADD_PROXY,
  ADD_PROXY_SUCCESS,
  GET_PROXY,
  GET_PROXY_SUCCESS,
  EDIT_PROXY,
  EDIT_PROXY_SUCCESS,
} from './actionTypes';

export const editProxy = (payload) => {
  return {
    type: EDIT_PROXY,
    payload,
  };
};
export const editProxySuccess = () => {
  return {
    type: EDIT_PROXY_SUCCESS,
  };
};

export const addProxy = (payload) => {
  return {
    type: ADD_PROXY,
    payload,
  };
};
export const addProxySuccess = (payload) => {
  return {
    type: ADD_PROXY_SUCCESS,
    payload,
  };
};

export const getProxy = (payload) => {
  return {
    type: GET_PROXY,
    payload,
  };
};

export const getProxySuccess = (payload) => {
  return {
    type: GET_PROXY_SUCCESS,
    payload,
  };
};

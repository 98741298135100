import FirstVerticalSnake from '../../../assets/account/AccountCardOneVerticalSnake.svg';
import FirstHorizontalSnake from '../../../assets/account/AccountCardOneHorizontalSnake.svg';
import SecondVerticalSnake from '../../../assets/account/AccountCardTwoVerticalSnake.svg';
import SecondHorizontalSnake from '../../../assets/account/AccountCardTwoHorizontalSnake.svg';

export const downloadCards = [
  {
    title: 'Windows version',
    imgH: FirstHorizontalSnake,
    imgV: FirstVerticalSnake,
  },
  {
    title: 'MacOS version',
    imgH: SecondHorizontalSnake,
    imgV: SecondVerticalSnake,
  },
];

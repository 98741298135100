import { delay, put, takeEvery } from 'redux-saga/effects';

import {
  apiError,
  loginSuccess,
  logoutSuccess,
  registerSuccess,
  updateCredentialsSuccess,
  updateTokenSuccess,
} from './actions';
import {
  logout,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  updateFirebaseProfile,
} from '../../firebases/auth';
import {
  AUTH_UPDATE,
  LOGIN,
  LOGOUT,
  REGISTER,
  UPDATE_CREDENTIALS,
} from './actionTypes';
import { firebaseErrFormatter } from '../../utils/firebaseErrorFormatter';
import { toastSuccess, toastWarning } from '../../utils/toasts';
import { initUserDoc } from '../../firebases/docs';

function* registerUserSaga({ payload: { form, navigate } }) {
  try {
    const { uid } = yield registerWithEmailAndPassword(
      form.name,
      form.email,
      form.password
    );
    yield delay(3000);
    yield put(registerSuccess({ isAuth: true, user: form.name }));

    yield initUserDoc(uid);
    navigate('/account');
    toastSuccess('Profile successfully created');
  } catch (error) {
    toastWarning(firebaseErrFormatter(error.message));

    yield put(apiError());
  }
}

function* updateUserSaga({ payload: { name, email, password, navigate } }) {
  try {
    yield updateFirebaseProfile(name, email, password);
    yield delay(3000);
    yield put(updateCredentialsSuccess(name));

    navigate('/account');
    toastSuccess('Profile successfully updated');
  } catch (error) {
    toastWarning(firebaseErrFormatter(error.message));
    yield put(apiError());
  }
}

function* loginUserSaga({
  payload: { user, navigate, isEditProfileRedirect },
}) {
  try {
    const { displayName, isAdmin } = yield logInWithEmailAndPassword(
      user.email,
      user.password
    );

    yield put(loginSuccess({ isAuth: true, user: displayName, isAdmin }));
    yield delay(1000);
    const navigationLink = isEditProfileRedirect ? '/edit-profile' : '/account';
    navigate(navigationLink);
  } catch (error) {
    toastWarning(firebaseErrFormatter(error.message));
    yield put(apiError(error.message));
  }
}

function* logoutUserSaga() {
  try {
    yield logout();
    yield delay(3000);
    yield put(logoutSuccess());
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authUpdateSaga({ payload }) {
  try {
    yield put(updateTokenSuccess(payload));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN, loginUserSaga);
  yield takeEvery(LOGOUT, logoutUserSaga);
  yield takeEvery(REGISTER, registerUserSaga);
  yield takeEvery(AUTH_UPDATE, authUpdateSaga);
  yield takeEvery(UPDATE_CREDENTIALS, updateUserSaga);
}

export default authSaga;

import './styles.scss';

const BlurContainer = ({ children }) => (
  <div className="blur-container">
    <span className="blur-title karla">Coming Soon</span>
    <div className="blur-preventer" />
    <div className="blur-child">{children}</div>
  </div>
);

export default BlurContainer;

import {
  User,
  signOut,
  updateEmail,
  updateProfile,
  EmailAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  reauthenticateWithCredential,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '.';
import { checkForAdminRoots } from './docs';

export const logInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<{ displayName: string; isAdmin: boolean }> => {
  const {
    user: { uid, displayName },
  } = (await signInWithEmailAndPassword(auth, email, password)) as any;

  const isAdmin = await checkForAdminRoots(uid);

  return { displayName, isAdmin };
};

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
): Promise<User> => {
  const { user } = await createUserWithEmailAndPassword(auth, email, password);

  await updateProfile(user, {
    displayName: name,
  });

  return user;
};

export const updateFirebaseProfile = (
  name: string,
  email: string,
  password: string
) => {
  const currentUser = auth.currentUser;

  if (currentUser) {
    const currentEmail = currentUser.email as string;
    const credentials = EmailAuthProvider.credential(currentEmail, password);

    try {
      reauthenticateWithCredential(currentUser, credentials).then(() =>
        updateEmail(currentUser, email).then(() => {
          updateProfile(currentUser, {
            displayName: name,
          });
        })
      );
    } catch (error) {
      return new Error('Error updating profile');
    }

    return;
  }

  throw new Error('User not logged in');
};

export const sendPasswordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email);
};

export const logout = () => {
  signOut(auth);
};

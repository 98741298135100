import './styles.scss';
import PageNotFound from 'pages/NotFoundPage/index.js';

const SubscriptionsPage = () => (
  // <section className="subscriptions is-four-fifth is-column content-center align-center">
  //   <div className="subscriptions-heading cairo">Choose subscription plan</div>
  //   <div className="subscriptions-cards">
  //     {cardList.map((card) => (
  //       <Card key={`${v4()}-sub-cards`} card={card} />
  //     ))}
  //   </div>
  // </section>

  <PageNotFound isUnderDev />
);

export default SubscriptionsPage;

import { getProxyDoc } from '../../../../../firebases/docs';
import AutocompleteField from '../AutocompleteField';

const optionsMapper = ({ firstName, lastName, id }) => ({
  label: `${(firstName, lastName)}`,
  value: id,
});

const ProxyAutocomplete = (props) => {
  return (
    <AutocompleteField
      {...props}
      fetchDataCall={getProxyDoc}
      optionsMapper={props.optionsMapper || optionsMapper}
    />
  );
};

export default ProxyAutocomplete;

export const prefix = '/@dmin-d@shboard';
export const links = [
  {
    to: '/news',
    name: 'News',
  },
  {
    to: '/faq',
    name: 'FAQ',
  },
  {
    to: '/proxy',
    name: 'Proxy',
  },
  {
    to: '/tutorials',
    name: 'Tutorials',
  },
];

import { useLocation } from 'react-router-dom';
import Button from '../../components/Buttons/Button';

import ImgPageNotFound from '../../assets/PageNotFound.jpeg';

import './styles.scss';

const PageNotFound = ({ accessDenied, isUnderDev }) => {
  const { pathname } = useLocation();

  const isEditProfile = pathname.includes('/edit-profile');

  return (
    <div className="page-not-found content-center align-center is-column">
      <span className="not-found-text cairo white">
        {accessDenied
          ? 'Sorry, but you are not allowed to access this'
          : isUnderDev
          ? 'Sir, this page is under development'
          : isEditProfile
          ? 'Sir, login to continue'
          : 'Sir, it seems to be a wrong door'}
      </span>
      {isEditProfile ? (
        <Button path="/log-in?edit-profile" text="Log In" />
      ) : (
        <Button path="/" text="Home" />
      )}

      <img src={ImgPageNotFound} alt="not found" className="not-found-img" />
    </div>
  );
};

export default PageNotFound;

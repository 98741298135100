import Hero from './components/Hero';
import Mouse from './components/Mouse';
import Work from './components/Work';
import Prices from './components/Prices';

import IconSite from '../../../assets/ImgDesktop.png';

import './styles.scss';

const HomePage = () => (
  <>
    <Hero />
    <Mouse />
    <Work />
    <img src={IconSite} alt="" className="site-img" id="appearance" />
    <Prices />
  </>
);

export default HomePage;

import { GET_USER_INFO, GET_USER_INFO_SUCCESS } from './actionTypes';

export const getUserInfo = (uid) => {
  return {
    type: GET_USER_INFO,
    payload: uid,
  };
};

export const getUserInfoSuccess = (user) => {
  return {
    type: GET_USER_INFO_SUCCESS,
    payload: user,
  };
};

import { useMediaQuery } from '@mantine/hooks';
import { useAuthState } from 'react-firebase-hooks/auth';

import { auth } from 'firebases';
import { parse } from 'utils/hash';
import { getParams } from 'utils/params';
import { LevelLabel, LevelLowLabel, LevelPrice } from 'enums/lvl.enum';

import CheckoutFormContainer from 'components/Forms/CheckoutForm/CheckoutFormContainer';

import IconPacs from 'assets/auth/LogInPacs.svg';
import IconCheckoutPageIllustration from 'assets/CheckoutPageIllustration.svg';

import './styles.scss';

const CheckoutPage = () => {
  const notDesktop = useMediaQuery('(max-width: 1240px)');
  const hashedParam = getParams('lvl');
  const lvl = parse(hashedParam);

  const [user] = useAuthState(auth);
  const uid = user?.uid;

  return (
    <section className="checkout">
      <div className="checkout-img-container">
        <img
          src={notDesktop ? IconPacs : IconCheckoutPageIllustration}
          alt="checkout-img"
          className="checkout-img"
        />
      </div>
      <div className="checkout-content">
        <div className="checkout-level">
          <div className="checkout-level-text karla">Subscription level</div>
          <div
            className={`checkout-level-data label-${LevelLowLabel[lvl]} cairo`}
          >
            {LevelLabel[lvl]}
          </div>
        </div>
        <div className="checkout-price">
          <div className="checkout-price-text karla">Price</div>
          <div className={`checkout-price-data karla-bold`}>
            ${LevelPrice[lvl]}
          </div>
        </div>
        <div className="checkout-payment">
          <div className="checkout-payment-text karla">Payment method</div>
          <CheckoutFormContainer lvl={lvl} uid={uid} />
        </div>
      </div>
    </section>
  );
};

export default CheckoutPage;

import AccountData from '../AccountData';
import AccountLinks from '../AccountLinks';
import AccountSubscriptions from '../AccountSubscriptions';

import './styles.scss';

const AccountInfoContainer = () => (
  <div className="account-info">
    <div className="account-info-container">
      <div className="account-heading cairo">Account overview</div>
      <AccountData />
      <AccountSubscriptions />
    </div>
    <AccountLinks />
  </div>
);

export default AccountInfoContainer;

import { Link } from 'react-router-dom';

const Links = () => (
  <div className="is-row align-center">
    <a href="#work" className="header-href karla f-16 black no-decorators">
      How it works?
    </a>
    <a
      href="#appearance"
      className="header-href karla f-16 black no-decorators"
    >
      Appearance
    </a>
    <a href="#price" className="header-href karla f-16 black no-decorators">
      Costs
    </a>

    <Link to="/faq" className="header-href karla f-16 black no-decorators">
      FAQ
    </Link>
  </div>
);

export default Links;

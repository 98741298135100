import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useLayoutEffect, useState } from 'react';

import Searcher from './components/Searcher';
import FaqArticlesContainer from './components/FaqArticlesContainer';
import FaqFormContainer from '../../../components/Forms/FaqForm/FaqFormContainer';

import { getArticle } from '../../../store/actions';

import './styles.scss';

const FaqPage = () => {
  const dispatch = useDispatch();
  const { article } = useSelector((state) => state.Article);

  const [filterValue, setFilterValue] = useState('all');
  const [filteredArticles, setFilteredArticles] = useState('');

  useLayoutEffect(() => {
    setFilteredArticles(article);
  }, [article]);

  useEffect(() => {
    dispatch(
      getArticle({
        collectionName: 'faq',
        filter: 'type',
        filterValue: filterValue,
      })
    );
    document.getElementById('faq-search').value = '';
  }, [filterValue, dispatch]);

  return (
    <section className="content-center align-center is-column">
      <span className="faq-title cairo">FAQ</span>

      <Searcher setFilteredArticles={setFilteredArticles} />
      <FaqFormContainer onSubmit={setFilterValue} />
      <FaqArticlesContainer filteredArticles={filteredArticles} />
    </section>
  );
};

export default memo(FaqPage);

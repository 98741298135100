import * as Yup from 'yup';

const getValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .max(33, 'Title is too long')
      .min(5, 'Title is too short'),
    type: Yup.string().required('Type is required'),
    textarea: Yup.string()
      .required('Text is required')
      .max(300, 'Text is too long')
      .min(50, 'Text is too short'),
  });
};

export default getValidationSchema;

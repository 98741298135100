import BurgerButton from '../../../Buttons/BurgerButton';
import MobileSideBar from '../../MobileSidebar';
import { buttonToRender } from '../buttonToRender';

const MobileHeader = ({
  user,
  isOpen,
  navigate,
  dispatch,
  isMainPage,
  isLogInPage,
  isAuth,
  setOpenStatus,
  isAccountPage,
}) => (
  <>
    {isMainPage ? (
      <BurgerButton isOpen={isOpen} onClick={() => setOpenStatus(!isOpen)} />
    ) : (
      <div className="align-center content-between header-box">
        {isAccountPage && <h3>Hi, {user}</h3>}
        {buttonToRender(isAccountPage, isLogInPage, isAuth, navigate, dispatch)}
      </div>
    )}

    <MobileSideBar
      onClick={() => setOpenStatus(!isOpen)}
      isOpen={isOpen}
      isAuth={isAuth}
    />
  </>
);

export default MobileHeader;

import AccountSubscriptionInfo from './components/AccountSubscriptionInfo';
import AuthSubLvlConditionalBtnRender from './components/AuthSubLvlConditionalBtnRender';

import './styles.scss';

const AccountSubscriptions = () => {
  return (
    <div className="account-subscription">
      <div className="account-subscription-data">
        <div className="account-subscription-heading cairo">Current plan</div>
        <AccountSubscriptionInfo />
      </div>
      <AuthSubLvlConditionalBtnRender />
    </div>
  );
};

export default AccountSubscriptions;

import { Form } from 'formik';
import { useSelector } from 'react-redux';

import Button from '../../Buttons/Button';
import { FormField } from '../../Formik/Fields';

import './styles.scss';

const formFields = () =>
  [
    {
      id: 'name',
      name: 'name',
      label: 'Name',
      type: 'text',
      placeholder: 'Your name',
      show: true,
    },
    {
      id: 'subject',
      name: 'subject',
      label: 'Subject',
      type: 'text',
      placeholder: 'Subject',
      show: true,
    },
    {
      id: 'email',
      name: 'email',
      label: 'Email',
      type: 'email',
      placeholder: 'Your email',
      show: true,
    },
  ].filter(({ show }) => show);

const textField = () => [{
  id: 'message',
  name: 'message',
  label: 'Message text',
  type: 'text',
  placeholder: 'Your text',
  show: true,
  as: 'textarea',
  customClassName: 'full-width-field',
  divCustomClassName: 'full-width',
  errorCustomClassName: 'full-width-error',
}].filter(({ show }) => show);

const renderFormField = ({ show, ...fieldProps }) => (
  <FormField key={fieldProps.name} {...fieldProps} />
);

const ContactForm = ({ initialValues }) => {
  const { loading } = useSelector((state) => state.Contact);

  return (
    <Form className="contact-form is-column align-center" autoComplete="off">
      <div className="field-inputs-row">
        {formFields({
          initialValues,
        }).map(renderFormField)}
      </div>
      <div className="form-inputs-textarea">
        {textField({initialValues}).map(renderFormField)}
      </div>

      <div className="full-width content-center">
        <Button text="Send" loading={loading} />
      </div>
    </Form>
  );
};

export default ContactForm;

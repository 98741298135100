import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/saga';
import userSaga from './user/saga';
import proxySaga from './proxy/saga';
import contactSaga from './contact/saga';
import articleSaga from './articles/saga';
import checkoutSaga from './checkout/saga';

export default function* rootSaga() {
  yield all([
    //auth
    fork(authSaga),

    //user
    fork(userSaga),

    //article
    fork(articleSaga),

    //proxy
    fork(proxySaga),

    //contact
    fork(contactSaga),

    //checkout
    fork(checkoutSaga),
  ]);
}

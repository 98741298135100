import { API_ERROR } from '../auth/actionTypes';
import {
  ADD_ARTICLE,
  ADD_ARTICLE_SUCCESS,
  GET_ARTICLE,
  GET_ARTICLE_SUCCESS,
  EDIT_ARTICLE,
  EDIT_ARTICLE_SUCCESS,
} from './actionTypes';

const initialState = {
  article: [],
  error: null,
  loading: false,
};

const redactors = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case EDIT_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GET_ARTICLE:
      return {
        ...state,
        loading: true,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        article: action.payload,
        loading: false,
      };
    case API_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default redactors;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

import MobileHeader from './mobile';
import DesktopHeader from './components/DesktopHeader';

import IconLogo from '../../../assets/Logo.svg';

import './styles.scss';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isOpen, setOpenStatus] = useState(false);
  const isMobile = useMediaQuery('(max-width: 770px)');
  const { isAuth, user, isAdmin } = useSelector((state) => state.Auth);

  const isMainPage = pathname === '/';
  const isLogInPage = pathname.includes('/log-in');
  const isAccountPage = pathname.includes('/account');
  const isAdminPage = pathname.includes('/@dmin-d@shboard');

  useEffect(() => {
    if (!isMainPage) {
      setOpenStatus(false);
    }
    if (!isMobile) {
      setOpenStatus(false);
    }
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isMainPage, isMobile, isOpen]);

  return (
    <section className="header content-center align-center is-nine-tenth">
      <div className="header-container is-row content-between">
        <Link to="/" className="header-logo-container">
          <img src={IconLogo} alt="GSINS logo" className="header-logo" />
        </Link>

        {isMobile ? (
          <MobileHeader
            user={user}
            isOpen={isOpen}
            navigate={navigate}
            dispatch={dispatch}
            isMainPage={isMainPage}
            isAuth={isAuth}
            isLogInPage={isLogInPage}
            isAccountPage={isAccountPage}
            setOpenStatus={setOpenStatus}
          />
        ) : (
          <DesktopHeader
            user={user}
            isAdmin={isAdmin}
            navigate={navigate}
            dispatch={dispatch}
            isMainPage={isMainPage}
            isLogInPage={isLogInPage}
            isAuth={isAuth}
            isAccountPage={isAccountPage}
            isAdminPage={isAdminPage}
          />
        )}
      </div>
    </section>
  );
};

export default Header;

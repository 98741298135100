import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { logout, updateToken } from '../../../store/actions';

import PageNotFound from '../../../pages/NotFoundPage';

const RoleManager = ({ children }) => {
  const { isAuth, user: reduxUser } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();
  const ref = useRef(true);

  useEffect(() => {
    const auth = getAuth();
    const currentUser = auth?.currentUser?.displayName;

    onAuthStateChanged(auth, (userChanged) => {
      console.log('userChanged', userChanged);

      if (userChanged && isAuth) {
        if (
          userChanged?.displayName === reduxUser ||
          currentUser === reduxUser
        ) {
          dispatch(updateToken(isAuth));
        } else {
          ref.current = false;
          dispatch(logout());
        }
      }

      if (!userChanged) {
        ref.current = false;
      }
    });
  }, [isAuth, dispatch, reduxUser]);

  switch (ref.current) {
    case true:
      return children;
    default:
      return <PageNotFound accessDenied={true} />;
  }
};

export default RoleManager;

import { useState } from 'react';

import ArticleRedactor from './ArticleRedactor';
import Button from '../../../../components/Buttons/Button';

import './styles.scss';
import { v4 } from 'uuid';

const Redactor = ({ type, handleAddSubmit, handleEditSubmit }) => {
  const formats = [
    { title: 'Add', onSubmit: handleAddSubmit },
    { title: 'Edit', onSubmit: handleEditSubmit },
  ];

  const [format, setFormat] = useState(formats[0].title);

  return (
    <section className="redactor-container">
      <div className="heading karla">
        {formats.map(({ title }) => (
          <Button
            text={title}
            key={`redactor-btn-${v4()}`}
            onClick={() => setFormat(title)}
            color={`trans ${format.includes(title) ? 'active' : ''}`}
          />
        ))}
      </div>

      {formats.map(
        (renderedFormat) =>
          format.includes(renderedFormat.title) && (
            <ArticleRedactor
              key={'article-redactor' + v4()}
              format={renderedFormat}
              type={type}
            />
          )
      )}
    </section>
  );
};

export default Redactor;

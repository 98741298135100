import RegisterFormContainer from '../../../components/Forms/RegisterForm/RegisterFormContainer';

import IconSignUpSnakeYellow from '../../../assets/auth/SignUpSnakeYellow.svg';
import IconSignUpSnakeGreen from '../../../assets/auth/SignUpSnakeGreen.svg';
import IconSignUpSnakeBlue from '../../../assets/auth/SignUpSnakeBlue.svg';
import IconSignUpSnakeYellowGreen from '../../../assets/auth/SignUpSnakeYellowGreen.svg';

import './styles.scss';

const SignUpPage = () => (
  <section className="sign-up is-four-fifth is-column content-center align-center">
    <div className="sign-up-heading cairo">
      <span>Sign up</span>
    </div>
    <RegisterFormContainer />
    <img src={IconSignUpSnakeYellow} alt="" className="snake-yellow" />
    <img src={IconSignUpSnakeGreen} alt="" className="snake-green" />
    <img src={IconSignUpSnakeBlue} alt="" className="snake-blue" />
    <img
      src={IconSignUpSnakeYellowGreen}
      alt=""
      className="snake-yellow-green"
    />
  </section>
);

export default SignUpPage;

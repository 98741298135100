import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebases';
import { sendPasswordResetEmail } from 'firebase/auth';
import { toastSuccess, toastWarning } from '../../../utils/toasts';
import { firebaseErrFormatter } from '../../../utils/firebaseErrorFormatter';

import PasswordResetForm from './PasswordResetForm';
import getValidationSchema from './passwordResetFormValidationSchema';

const PasswordResetFormContainer = ({
  initialValues = {
    email: '',
  },
}) => {
  const navigate = useNavigate();

  const resetPassword = (email) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toastSuccess('Password reset link sent');
        navigate('/log-in');
      })
      .catch(() => {
        toastWarning(firebaseErrFormatter('user-not-found'));
      });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={({ email }) => resetPassword(email)}
    >
      {({ values }) => <PasswordResetForm values={values} />}
    </Formik>
  );
};

export default PasswordResetFormContainer;

import { Link } from 'react-router-dom';

import LogInFormContainer from '../../../components/Forms/LogInForm/LogInFormContainer';

import IconLogInPacs from '../../../assets/auth/LogInPacs.svg';

import './styles.scss';
import { checkIfParamExists } from 'utils/params';

const LogInPage = () => {
  const isEditProfileRedirect = checkIfParamExists('edit-profile');
  return (
    <>
      <section className="log-in is-four-fifth is-column content-start align-center">
        <div className="log-in-container">
          <div className="log-in-heading content-center cairo">
            <span>Log in</span>
          </div>
          <LogInFormContainer isEditProfileRedirect={isEditProfileRedirect} />
          <div className="password-reset-button-container">
            <Link className="password-reset-button karla" to="/password-reset">
              Forgot password
            </Link>
          </div>
        </div>
      </section>
      <img src={IconLogInPacs} alt="" className="log-in-pacs" />
    </>
  );
};

export default LogInPage;

import { logout } from '../../../store/actions';
import Button from '../../Buttons/Button';

function logOutProcess(navigate, dispatch) {
  dispatch(logout());
  navigate('/', { replace: true });
}

const acc = {
  path: '/account',
  text: 'Account',
};
const signUp = {
  path: '/sign-up',
  text: 'Sign up',
};
const logIn = {
  path: '/log-in',
  text: 'Log in',
};
const logOut = {
  text: 'Log out',
};

export function buttonToRender(
  isAccountPage,
  isLogInPage,
  isAuth,
  navigate,
  dispatch,
  isAdmin
) {
  return (
    <>
      {isAuth ? (
        <>
          {isAccountPage ? (
            <>
              {isAdmin && (
                <Button path="/@dmin-d@shboard/news" text="Dashboard" />
              )}

              <Button
                color="trans"
                onClick={() => logOutProcess(navigate, dispatch)}
                text={logOut.text}
              />
            </>
          ) : (
            <Button path={acc.path} text={acc.text} />
          )}
        </>
      ) : (
        <>
          {isLogInPage ? (
            <Button path={signUp.path} text={signUp.text} />
          ) : (
            <Button path={logIn.path} text={logIn.text} />
          )}
        </>
      )}
    </>
  );
}

import classNames from 'classnames';
import { Field } from 'formik';
import './styles.scss';

const DropdownFormField = ({
  component,
  name,
  label,
  type,
  id,
  customClassName,
  divCustomClassName,
  errorCustomClassName,
  active,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        `dropdown-input-container is-column align-center ${divCustomClassName}`
      )}
    >
      <label className="dropdown-label karla" htmlFor={id}>
        {label}
      </label>
      <Field
        name={name}
        id={id}
        {...(component && { component })}
        {...(type && { type })}
        {...restProps}
        className={classNames('dropdown-field karla', customClassName)}
      />
    </div>
  );
};

export default DropdownFormField;

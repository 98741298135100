import { Formik } from 'formik';

import ArticleForm from './ArticleForm';
import getValidationSchema from './articlePageFormValidationSchema';
import { Timestamp } from 'firebase/firestore';

import { v4 as uuidv4 } from 'uuid';

const ArticleFormContainer = ({
  type,
  title,
  onSubmit,
  initialValues = {
    title: '',
    type: 'update',
    textarea: '',
  },
}) => {
  return (
    <Formik
      enableReinitialize
      onSubmit={(form) =>
        onSubmit({
          ...form,
          id: uuidv4(),
          date: Timestamp.fromDate(new Date()),
          collection: type,
        })
      }
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
    >
      {({ values }) => (
        <ArticleForm values={values} title={title} type={type} />
      )}
    </Formik>
  );
};

export default ArticleFormContainer;

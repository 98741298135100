import { Form, useFormikContext } from 'formik';
import Button from '../../Buttons/Button';
import FormField from '../../Formik/Fields/FormField';
import SelectFormField from '../../Formik/Fields/SelectFormField';
import TitleAutocomplete from '../../Formik/Fields/autocomplete/TitleAutocomplete';

import './styles.scss';
import { useSelector } from 'react-redux';

const formInputs = ({ title, type, setFieldValue }) => [
  {
    id: 'title',
    name: 'title',
    label: 'Title',

    type: 'text',
    collection: type,
    setFieldValue,
    otherValueAutoSet: ['type', 'textarea'],
    ...(title === 'Edit' && { component: TitleAutocomplete }),
  },
  {
    id: 'type',
    name: 'type',
    label: 'Type',

    type: 'select',

    isMulti: false,
    isClearable: false,
    component: SelectFormField,
    options: [
      { value: 'update', label: 'Update' },
      { value: 'news', label: 'News' },
      { value: 'tips', label: 'Tips' },
    ],
  },
];

const formText = () => [
  {
    label: 'Text',

    id: 'textarea',
    as: 'textarea',
    name: 'textarea',
    type: 'textarea',

    divCustomClassName: 'article-text-area-container',
    customClassName: 'article-text-area',
  },
];

const renderFormField = ({ ...fieldProps }) => (
  <FormField key={fieldProps.name} {...fieldProps} />
);

const ArticleForm = ({ values, title, type }) => {
  const { loading } = useSelector((state) => state.Article);
  const { resetForm, setFieldValue } = useFormikContext();

  return (
    <Form className="article-form" autoComplete="off">
      <div className="fields-container">
        <div className="inputs-container">
          {formInputs({
            setFieldValue,
            values,
            title,
            type,
          }).map(renderFormField)}
        </div>

        {formText({
          values,
        }).map(renderFormField)}
      </div>

      <div className="btn-container">
        <Button text="Clear" color="trans" onClick={resetForm} />

        <Button text="Submit" color="primary" loading={loading} />
      </div>
    </Form>
  );
};

export default ArticleForm;
